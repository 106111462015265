import i18n from '../../i18n'
import './Language.css'
import {
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
} from "@material-tailwind/react";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {AmericaFlagIcon, ArmeniaFlagIcon, RussiaFlagIcon} from "../../img/Component_svg/Component_svg.jsx";


const Language = () => {
    const { t } = useTranslation();
    let leng = localStorage.getItem('i18nextLng')
    const [age, setAge] = useState(leng ? leng :'hy');
    const [openMenu, setOpenMenu] = useState(false);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng).then(r =>  {return r});
    }

    const handleChange = (event) => {
        if(event.target.value || event.target.parentElement.value){
            setAge(event?.target?.value || event?.target?.parentElement?.value);
            changeLanguage(event?.target?.value || event?.target?.parentElement?.value)
        }

    };

    return (<>
        <Menu
            open={openMenu}
            handler={setOpenMenu}
            allowHover
            offset={5}>
            <MenuHandler>
                <div className={'MenuHandler'}>
                    {age === 'hy' && <ArmeniaFlagIcon />}
                    {age === 'en' && <AmericaFlagIcon />}
                    {age === 'ru' && <RussiaFlagIcon  />}
                </div>
            </MenuHandler>
            <MenuList className={'min-w-[100px]'} onClick={handleChange}>
                <MenuItem className={'MenuHandler_item'} value={'hy'} ><ArmeniaFlagIcon /> <span>Հայ․ </span> </MenuItem>
                {/*<MenuItem className={'MenuHandler_item'} value={'en'} ><AmericaFlagIcon /> <span>Eng. </span></MenuItem>*/}
                <MenuItem className={'MenuHandler_item'} value={'ru'} ><RussiaFlagIcon  /> <span>Рус․ </span></MenuItem>
            </MenuList>
        </Menu>
        </>)
}

export default  Language;


