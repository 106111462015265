export const AmericaFlagIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 36 36"
            width="1.3em"
            height="1.3em"
            className={'rounded-3xl'}
        >
            <path
                fill="#B22334"
                d="M35.445 7C34.752 5.809 33.477 5 32 5H18v2h17.445zM0 25h36v2H0zm18-8h18v2H18zm0-4h18v2H18zM0 21h36v2H0zm4 10h28c1.477 0 2.752-.809 3.445-2H.555c.693 1.191 1.968 2 3.445 2zM18 9h18v2H18z"
            />
            <path
                fill="#EEE"
                d="M.068 27.679c.017.093.036.186.059.277.026.101.058.198.092.296.089.259.197.509.333.743L.555 29h34.89l.002-.004c.135-.233.243-.483.332-.741.034-.099.067-.198.093-.301.023-.09.042-.182.059-.275.041-.22.069-.446.069-.679H0c0 .233.028.458.068.679zM0 23h36v2H0zm0-4v2h36v-2H18zm18-4h18v2H18zm0-4h18v2H18zM0 9c0-.233.03-.457.068-.679C.028 8.542 0 8.767 0 9zm.555-2l-.003.005L.555 7zM.128 8.044c.025-.102.06-.199.092-.297-.034.098-.066.196-.092.297zM18 9h18c0-.233-.028-.459-.069-.68-.017-.092-.035-.184-.059-.274-.027-.103-.059-.203-.094-.302-.089-.258-.197-.507-.332-.74.001-.001 0-.003-.001-.004H18v2z"
            />
            <path fill="#3C3B6E" d="M18 5H4C1.791 5 0 6.791 0 9v10h18V5z"/>
            <path
                fill="#FFF"
                d="M2.001 7.726l.618.449-.236.725L3 8.452l.618.448-.236-.725L4 7.726h-.764L3 7l-.235.726zm2 2l.618.449-.236.725.617-.448.618.448-.236-.725L6 9.726h-.764L5 9l-.235.726zm4 0l.618.449-.236.725.617-.448.618.448-.236-.725.618-.449h-.764L9 9l-.235.726zm4 0l.618.449-.236.725.617-.448.618.448-.236-.725.618-.449h-.764L13 9l-.235.726zm-8 4l.618.449-.236.725.617-.448.618.448-.236-.725.618-.449h-.764L5 13l-.235.726zm4 0l.618.449-.236.725.617-.448.618.448-.236-.725.618-.449h-.764L9 13l-.235.726zm4 0l.618.449-.236.725.617-.448.618.448-.236-.725.618-.449h-.764L13 13l-.235.726zm-6-6l.618.449-.236.725L7 8.452l.618.448-.236-.725L8 7.726h-.764L7 7l-.235.726zm4 0l.618.449-.236.725.617-.448.618.448-.236-.725.618-.449h-.764L11 7l-.235.726zm4 0l.618.449-.236.725.617-.448.618.448-.236-.725.618-.449h-.764L15 7l-.235.726zm-12 4l.618.449-.236.725.617-.448.618.448-.236-.725.618-.449h-.764L3 11l-.235.726zM6.383 12.9L7 12.452l.618.448-.236-.725.618-.449h-.764L7 11l-.235.726h-.764l.618.449zm3.618-1.174l.618.449-.236.725.617-.448.618.448-.236-.725.618-.449h-.764L11 11l-.235.726zm4 0l.618.449-.236.725.617-.448.618.448-.236-.725.618-.449h-.764L15 11l-.235.726zm-12 4l.618.449-.236.725.617-.448.618.448-.236-.725.618-.449h-.764L3 15l-.235.726zM6.383 16.9L7 16.452l.618.448-.236-.725.618-.449h-.764L7 15l-.235.726h-.764l.618.449zm3.618-1.174l.618.449-.236.725.617-.448.618.448-.236-.725.618-.449h-.764L11 15l-.235.726zm4 0l.618.449-.236.725.617-.448.618.448-.236-.725.618-.449h-.764L15 15l-.235.726z"
            />
        </svg>
    );
};

export const ArmeniaFlagIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 36 36"
            width="1.3em"
            height="1.3em"
            className={'rounded-3xl'}
        >
            <path fill="#D90012" d="M32 5H4C1.791 5 0 6.791 0 9v4h36V9c0-2.209-1.791-4-4-4z"/>
            <path fill="#F2A800" d="M4 31h28c2.209 0 4-1.791 4-4v-4H0v4c0 2.209 1.791 4 4 4z"/>
            <path fill="#0033A0" d="M0 13h36v10H0z"/>
        </svg>
    );
};

export const RussiaFlagIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 36 36"
            width="1.3em"
            height="1.3em"
            className={'rounded-3xl'}
        >
            <path fill="#CE2028" d="M36 27c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4v-4h36v4z"/>
            <path fill="#22408C" d="M0 13h36v10H0z"/>
            <path fill="#EEE" d="M32 5H4C1.791 5 0 6.791 0 9v4h36V9c0-2.209-1.791-4-4-4z"/>
        </svg>
    );
};

export const LinkedinIcon = () => {
    return (<>
            <svg
                viewBox="0 0 448 512"
                fill="currentColor"
                className="h-5 w-5"
            >
                <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
            </svg>
        </>
    );
};

export const  IconWhatsapp = () => {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="currentColor"
            className="h-5 w-5"
        >
            <path d="M16.6 14c-.2-.1-1.5-.7-1.7-.8-.2-.1-.4-.1-.6.1-.2.2-.6.8-.8 1-.1.2-.3.2-.5.1-.7-.3-1.4-.7-2-1.2-.5-.5-1-1.1-1.4-1.7-.1-.2 0-.4.1-.5.1-.1.2-.3.4-.4.1-.1.2-.3.2-.4.1-.1.1-.3 0-.4-.1-.1-.6-1.3-.8-1.8-.1-.7-.3-.7-.5-.7h-.5c-.2 0-.5.2-.6.3-.6.6-.9 1.3-.9 2.1.1.9.4 1.8 1 2.6 1.1 1.6 2.5 2.9 4.2 3.7.5.2.9.4 1.4.5.5.2 1 .2 1.6.1.7-.1 1.3-.6 1.7-1.2.2-.4.2-.8.1-1.2l-.4-.2m2.5-9.1C15.2 1 8.9 1 5 4.9c-3.2 3.2-3.8 8.1-1.6 12L2 22l5.3-1.4c1.5.8 3.1 1.2 4.7 1.2 5.5 0 9.9-4.4 9.9-9.9.1-2.6-1-5.1-2.8-7m-2.7 14c-1.3.8-2.8 1.3-4.4 1.3-1.5 0-2.9-.4-4.2-1.1l-.3-.2-3.1.8.8-3-.2-.3c-2.4-4-1.2-9 2.7-11.5S16.6 3.7 19 7.5c2.4 3.9 1.3 9-2.6 11.4" />
        </svg>
    );
}

export const  IconTelegram = () => {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="currentColor"
            className="h-5 w-5"
        >
            <path d="M11.944 0A12 12 0 000 12a12 12 0 0012 12 12 12 0 0012-12A12 12 0 0012 0a12 12 0 00-.056 0zm4.962 7.224c.1-.002.321.023.465.14a.506.506 0 01.171.325c.016.093.036.306.02.472-.18 1.898-.962 6.502-1.36 8.627-.168.9-.499 1.201-.82 1.23-.696.065-1.225-.46-1.9-.902-1.056-.693-1.653-1.124-2.678-1.8-1.185-.78-.417-1.21.258-1.91.177-.184 3.247-2.977 3.307-3.23.007-.032.014-.15-.056-.212s-.174-.041-.249-.024c-.106.024-1.793 1.14-5.061 3.345-.48.33-.913.49-1.302.48-.428-.008-1.252-.241-1.865-.44-.752-.245-1.349-.374-1.297-.789.027-.216.325-.437.893-.663 3.498-1.524 5.83-2.529 6.998-3.014 3.332-1.386 4.025-1.627 4.476-1.635z" />
        </svg>
    );
}

export const  Check_Mark = () => {
    return (
        <svg className="h-5 w-5 mx-1.5" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.84009 0.447139C9.14543 0.727033 9.16605 1.20146 8.88616 1.5068L4.30282 6.5068C4.1646 6.65759 3.97077 6.74538 3.76625 6.74983C3.56174 6.75427 3.36428 6.67498 3.21963 6.53033L1.1363 4.447C0.843403 4.15411 0.843403 3.67923 1.1363 3.38634C1.42919 3.09345 1.90406 3.09345 2.19696 3.38634L3.7264 4.91579L7.78043 0.493211C8.06032 0.187872 8.53475 0.167244 8.84009 0.447139Z" fill="#2B59FA"/>
        </svg>
    );
}

export const  R_keeper = () => {
    return (
        <svg
             width="30px"
             height="30px"
             viewBox="0 0 45 30"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M12.2954 8.2793V12.8563C11.8713 12.7784 11.4865 12.7405 11.1416 12.7405C9.20445 12.7405 7.69276 13.2858 6.60438 14.375C5.51672 15.4655 4.97361 17.0354 4.97361 19.086V29.0478H0V8.51018H2.42872L4.73546 11.5096C6.16801 9.35513 8.68821 8.2793 12.2954 8.2793Z" fill="#1E1E1E"/>
                <path d="M32.8222 29.0477H27.8486V6.91282L32.8222 0.509766V29.0477Z" fill="#1E1E1E"/>
                <path d="M46.8781 29.0477L38.7519 18.5329L46.632 8.47073L41.8725 8.43213L33.9663 18.5266L33.9975 18.549L42.0824 29.0105L46.8781 29.0477Z" fill="#1E1E1E"/>
                <path d="M29.9244 32.7594H7.64697L10.5447 29.0483H32.8222L29.9244 32.7594Z" fill="#00CF6F"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="50" height="40" fill="white" transform="translate(0 0.509766)"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export const  IconApplicationEditOutline = () => {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="currentColor"
            height="2em"
            width="2em"
        >
            <path d="M11 20v2H3c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h18c1.1 0 2 .9 2 2v8.1l-.2-.2c-.5-.5-1.1-.8-1.8-.8V6H3v14h8m10.4-6.7l1.3 1.3c.2.2.2.6 0 .8l-1 1-2.1-2.1 1-1c.1-.1.2-.2.4-.2s.3.1.4.2m-.3 3.6l-6 6.1H13v-2.1l6.1-6.1 2 2.1z" />
        </svg>
    );
}

export const  IconRestaurantOutline = () => {
    return (
        <svg
            viewBox="0 0 512 512"
            fill="currentColor"
            height="2em"
            width="2em"
        >
            <path
                fill="none"
                stroke="currentColor"
                strokeLinejoin="round"
                strokeWidth={32}
                d="M57.49 47.74l368.43 368.43a37.28 37.28 0 010 52.72h0a37.29 37.29 0 01-52.72 0l-90-91.55a32 32 0 01-9.2-22.43v-5.53a32 32 0 00-9.52-22.78l-11.62-10.73a32 32 0 00-29.8-7.44h0a48.53 48.53 0 01-46.56-12.63l-85.43-85.44C40.39 159.68 21.74 83.15 57.49 47.74z"
            />
            <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={32}
                d="M400 32l-77.25 77.25A64 64 0 00304 154.51v14.86a16 16 0 01-4.69 11.32L288 192M320 224l11.31-11.31a16 16 0 0111.32-4.69h14.86a64 64 0 0045.26-18.75L480 112M440 72l-80 80M200 368l-99.72 100.28a40 40 0 01-56.56 0h0a40 40 0 010-56.56L128 328"
            />
        </svg>
    );
}

export const IconPointOfSale = () => {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="currentColor"
            height="2em"
            width="2em"
        >
            <path d="M20 5c-.6 0-1 .5-1 1.1V16c0 .6.4 1 1 1.1.6 0 1-.5 1-1.1V6.1c0-.6-.4-1.1-1-1.1m-3.6-3H4.6C3.7 2 3 2.7 3 3.6v13.8c0 .9.7 1.6 1.6 1.6H6v2c0 .6.4 1 1 1h6c.6 0 1-.4 1-1v-2h2.4c.9 0 1.6-.7 1.6-1.6V3.6c0-.9-.7-1.6-1.6-1.6M5 8h3v2H5V8m7 3v2H9v-2h3m-3-1V8h3v2H9m3 4v2H9v-2h3m-7-3h3v2H5v-2m0 5v-2h3v2H5m3 5H7v-2h1v2m5 0h-3v-2h3v2m3-5h-3v-2h3v2m0-3h-3v-2h3v2m0-3h-3V8h3v2m0-4H5V4h11v2z" />
        </svg>
    );
}

export const IconDeviceGamepad2 = () => {
    return (
        <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            viewBox="0 0 24 24"
            height="2em"
            width="2em"
        >
            <path stroke="none" d="M0 0h24v24H0z" />
            <path d="M12 5h3.5a5 5 0 010 10H10l-4.015 4.227a2.3 2.3 0 01-3.923-2.035l1.634-8.173A5 5 0 018.6 5H12z" />
            <path d="M14 15l4.07 4.284a2.3 2.3 0 003.925-2.023l-1.6-8.232M8 9v2M7 10h2M14 10h2" />
        </svg>
    );
}

export const IconDumbbell = () => {
    return (
        <svg
            viewBox="0 0 640 512"
            fill="currentColor"
            height="2em"
            width="2em"
        >
            <path d="M112 96c0-17.7 14.3-32 32-32h16c17.7 0 32 14.3 32 32v320c0 17.7-14.3 32-32 32h-16c-17.7 0-32-14.3-32-32v-32H64c-17.7 0-32-14.3-32-32v-64c-17.7 0-32-14.3-32-32s14.3-32 32-32v-64c0-17.7 14.3-32 32-32h48V96zm416 0v32h48c17.7 0 32 14.3 32 32v64c17.7 0 32 14.3 32 32s-14.3 32-32 32v64c0 17.7-14.3 32-32 32h-48v32c0 17.7-14.3 32-32 32h-16c-17.7 0-32-14.3-32-32V96c0-17.7 14.3-32 32-32h16c17.7 0 32 14.3 32 32zM416 224v64H224v-64h192z" />
        </svg>
    );
}

export const IconHotelLine = () => {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="currentColor"
            height="2em"
            width="2em"
        >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M22 21H2v-2h1V4a1 1 0 011-1h14a1 1 0 011 1v5h2v10h1v2zm-5-2h2v-8h-6v8h2v-6h2v6zm0-10V5H5v14h6V9h6zM7 11h2v2H7v-2zm0 4h2v2H7v-2zm0-8h2v2H7V7z" />
        </svg>
    );
}

export const IconFastFoodOutline = () => {
    return (
        <svg
            viewBox="0 0 512 512"
            fill="currentColor"
            height="2em"
            width="2em"
        >
            <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={32}
                d="M322 416c0 35.35-20.65 64-56 64H134c-35.35 0-56-28.65-56-64M336 336c17.67 0 32 17.91 32 40h0c0 22.09-14.33 40-32 40H64c-17.67 0-32-17.91-32-40h0c0-22.09 14.33-40 32-40"
            />
            <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={32}
                d="M344 336H179.31a8 8 0 00-5.65 2.34l-26.83 26.83a4 4 0 01-5.66 0l-26.83-26.83a8 8 0 00-5.65-2.34H56a24 24 0 01-24-24h0a24 24 0 0124-24h288a24 24 0 0124 24h0a24 24 0 01-24 24zM64 276v-.22c0-55 45-83.78 100-83.78h72c55 0 100 29 100 84v-.22M241 112l7.44 63.97"
            />
            <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={32}
                d="M256 480h139.31a32 32 0 0031.91-29.61L463 112"
            />
            <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={32}
                d="M368 112l16-64 47-16"
            />
            <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={32}
                d="M224 112h256"
            />
        </svg>
    );
}

export const IconMovieOpenSettingsOutline = () => {
return (
        <svg
            viewBox="0 0 24 24"
            fill="currentColor"
            height="2em"
            width="2em"
        >
            <path d="M19.65 4.5L16.91.96l3.93-.78.78 3.92-1.97.4m-2.94.57l-2.74-3.53-1.97.39 2.75 3.53 1.96-.39m-4.9.98L9.07 2.5l-1.97.41 2.75 3.53 1.96-.39M4.16 3.5l-.98.19a1.995 1.995 0 00-1.57 2.35L2 8l4.9-.97L4.16 3.5M22 8v10c0 1.11-.89 2-2 2H4a2 2 0 01-2-2V8h20m-2 2H4v8h16v-8M7 24h2v-2H7v2m8 0h2v-2h-2v2m-4 0h2v-2h-2v2z" />
        </svg>
    );
}

export const Pcassa = () => {
    return (
        <svg
            height="30px"
            width="30px"
            viewBox="0 0 28 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5 0H0V10H12.5C15.2575 10 17.5 12.2425 17.5 15C17.5 17.7575 15.2575 20 12.5 20H0V40H10V30H12.5C20.77 30 27.5 23.27 27.5 15C27.5 6.73 20.77 0 12.5 0Z" fill="#5131E4"/>
            <path d="M5 20C7.76142 20 10 17.7614 10 15C10 12.2386 7.76142 10 5 10C2.23858 10 0 12.2386 0 15C0 17.7614 2.23858 20 5 20Z" fill="#9380EC"/>
        </svg>
    );
}

export const Shelter = () => {
    return (
        <svg
            height="30px"
            width="30px"
            viewBox="0 0 33 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9.25L16.0214 18.5V36.9999L0 27.7499V9.25Z" fill="url(#paint0_radial_269_9896)"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M32.041 9.25L16.0195 18.5V36.9999L32.041 27.7499V9.25Z" fill="url(#paint1_radial_269_9896)"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0214 0L32.0429 9.24997L16.0214 18.4999L0 9.24997L16.0214 0Z" fill="url(#paint2_radial_269_9896)"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.022 28.4204L16.022 18.4989L7.42969 13.5381L7.42969 23.4597L16.022 28.4204Z" fill="url(#paint3_linear_269_9896)"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.42969 13.5379L16.022 18.4987L24.6143 13.5379L16.022 8.57715L7.42969 13.5379Z" fill="url(#paint4_linear_269_9896)"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M24.6158 23.4597L24.6158 13.5381L16.0234 18.4989L16.0234 28.4204L24.6158 23.4597Z" fill="url(#paint5_linear_269_9896)"/>
            <defs>
                <radialGradient id="paint0_radial_269_9896" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.0214 18.5332) rotate(147.578) scale(18.9801 10.9975)">
                    <stop stop-color="#7BA0FF"/>
                    <stop offset="1" stop-color="#2F69FF"/>
                </radialGradient>
                <radialGradient id="paint1_radial_269_9896" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.0195 18.3742) rotate(27.1111) scale(27.9129 12.5663)">
                    <stop stop-color="#D2DFFF"/>
                    <stop offset="1" stop-color="#84A6FF"/>
                </radialGradient>
                <radialGradient id="paint2_radial_269_9896" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.0214 18.4999) rotate(-90) scale(18.4999 14.3516)">
                    <stop stop-color="#85A7FF"/>
                    <stop offset="1" stop-color="#0C40C4"/>
                </radialGradient>
                <linearGradient id="paint3_linear_269_9896" x1="7.1221" y1="23.2822" x2="16.0061" y2="18.4898" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FF9E2C"/>
                    <stop offset="1" stop-color="#FFC98A"/>
                </linearGradient>
                <linearGradient id="paint4_linear_269_9896" x1="16.2047" y1="18.3933" x2="15.9959" y2="8.59227" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFB55F"/>
                    <stop offset="1" stop-color="#C6720F"/>
                </linearGradient>
                <linearGradient id="paint5_linear_269_9896" x1="24.6158" y1="23.4599" x2="16.0235" y2="18.4991" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFBE71"/>
                    <stop offset="1" stop-color="#FFE1BE"/>
                </linearGradient>
            </defs>
        </svg>
    )
}

export const Game_Keeper = () => {
    return (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        height="30px"
        width="30px"
        viewBox="0 0 110 110"
        >
        <g>
            <path fill="#0088FF" d="M53.266,29.829c6.168,0,11.473,2.083,15.92,6.239h5.772c-0.655-0.843-1.358-1.638-2.107-2.389l-0.011-0.011
		c-2.482-2.482-5.432-4.447-8.827-5.913c-3.419-1.464-7.001-2.188-10.747-2.188c-3.746,0-7.329,0.725-10.748,2.188
		c-3.406,1.465-6.356,3.43-8.838,5.924c-0.619,0.621-1.216,1.276-1.768,1.944c-1.661,2.013-3.032,4.297-4.132,6.838v0.01
		c-0.013,0.013-0.013,0.036-0.023,0.049c-1.336,3.125-2.062,6.38-2.167,9.775v0.011c-0.011,0.328-0.023,0.632-0.023,0.961
		c0,3.746,0.725,7.328,2.19,10.747c1.462,3.406,3.43,6.356,5.922,8.838c2.482,2.494,5.432,4.46,8.838,5.924
		c3.419,1.464,7.003,2.19,10.748,2.19c3.746,0,7.493-0.387,10.747-2.19C74.9,72.746,78.505,65.358,78.798,63.919h-0.831H56.228
		v4.261h15.126c0,0-8.324,8.523-18.087,8.523c-6.475,0-12-2.284-16.578-6.86c-4.577-4.577-6.859-10.103-6.859-16.577
		c0-6.474,2.282-12,6.859-16.577C41.266,32.122,46.791,29.829,53.266,29.829"/>
            <path fill="#0088FF" d="M80.204,36.068h0.011h4.929c-0.178-0.328-0.328-0.655-0.516-0.972c-0.212-0.375-0.433-0.737-0.679-1.1
		c-0.071-0.105-0.141-0.212-0.199-0.328c-0.071-0.116-0.141-0.223-0.21-0.328c-0.107-0.163-0.21-0.315-0.317-0.479
		c-0.34-0.492-0.691-0.983-1.041-1.452c-0.105-0.141-0.21-0.281-0.304-0.409c-0.398-0.503-0.809-1.008-1.231-1.488
		c-0.045-0.058-0.092-0.116-0.15-0.175c-0.011-0.024-0.036-0.058-0.058-0.082c-0.165-0.175-0.33-0.362-0.505-0.539
		c-0.165-0.188-0.34-0.374-0.526-0.561c-0.154-0.176-0.317-0.34-0.48-0.516c-0.013,0-0.024-0.011-0.024-0.011v-0.011
		c-0.82-0.831-1.687-1.604-2.599-2.342c-0.293-0.246-0.608-0.492-0.914-0.726c-1.24-0.959-2.563-1.837-3.956-2.646
		c-5.56-3.243-11.625-4.858-18.168-4.858c-0.809,0-1.616,0.023-2.411,0.071c-0.353,0.023-0.703,0.047-1.056,0.081
		c-0.34,0.036-0.691,0.071-1.03,0.118c-0.349,0.034-0.689,0.081-1.041,0.141c-0.34,0.047-0.679,0.105-1.017,0.163
		c-0.34,0.058-0.679,0.128-1.019,0.199c-0.328,0.07-0.668,0.141-0.995,0.223c-1.008,0.244-1.992,0.526-2.974,0.854
		c-0.315,0.105-0.644,0.223-0.961,0.34c-0.328,0.116-0.644,0.246-0.97,0.374c-0.317,0.129-0.634,0.257-0.949,0.398
		c-0.317,0.141-0.632,0.281-0.948,0.433c-0.563,0.259-1.124,0.539-1.674,0.843c-0.364,0.188-0.737,0.399-1.101,0.61
		c-1.404,0.82-2.727,1.709-3.98,2.657c-0.936,0.726-1.826,1.486-2.681,2.271c-0.315,0.306-0.632,0.61-0.936,0.927
		c-0.268,0.268-0.526,0.548-0.796,0.83c-1.837,1.955-3.441,4.132-4.836,6.521c-0.619,1.066-1.182,2.154-1.661,3.256
		c-0.679,1.451-1.231,2.95-1.674,4.472c-0.024,0.07-0.047,0.152-0.071,0.235c-0.141,0.467-0.257,0.936-0.375,1.404
		c-0.023,0.071-0.034,0.152-0.058,0.235c-0.011,0.07-0.023,0.141-0.047,0.223c0,0.023-0.011,0.034-0.011,0.058
		c-0.082,0.34-0.163,0.679-0.222,1.019c-0.082,0.362-0.141,0.737-0.212,1.1c-0.047,0.293-0.094,0.597-0.139,0.89
		c-0.049,0.351-0.095,0.691-0.129,1.041c-0.049,0.387-0.082,0.786-0.118,1.171c0,0.071-0.011,0.129-0.011,0.199
		c-0.011,0.223-0.036,0.433-0.047,0.657v0.081h0.011c-0.024,0.387-0.045,0.773-0.058,1.16c0,0.328-0.024,0.644-0.024,0.972
		c0,6.543,1.616,12.608,4.847,18.168c3.243,5.562,7.645,9.962,13.205,13.193c5.562,3.244,11.615,4.858,18.171,4.858
		c6.543,0,12.608-1.614,18.168-4.858c5.562-3.231,9.962-7.632,13.193-13.193c0.619-1.066,1.158-2.153,1.663-3.254h0.034
		c1.827-4.027,2.88-8.277,3.126-12.784h-0.163h-33.06v4.261h28.389c-0.585,2.974-1.604,5.819-3.044,8.523h-0.036
		c-0.199,0.374-0.387,0.749-0.61,1.124c-2.854,4.894-6.731,8.769-11.623,11.625c-4.895,2.867-10.244,4.297-16.038,4.297
		c-5.808,0-11.146-1.43-16.04-4.297c-4.894-2.856-8.769-6.731-11.638-11.625c-2.854-4.894-4.284-10.244-4.284-16.038
		c0-5.795,1.43-11.144,4.284-16.038c2.869-4.894,6.744-8.769,11.638-11.638c4.894-2.856,10.232-4.284,16.04-4.284
		c5.795,0,11.143,1.428,16.038,4.284C73.799,28.225,77.44,31.713,80.204,36.068"/>
            <path fill="#0088FF" d="M94.602,36.068c-0.304-0.713-0.621-1.428-0.961-2.13c0-0.013-0.011-0.024-0.023-0.036
		c-0.188-0.409-0.398-0.82-0.608-1.229c-0.082-0.152-0.165-0.304-0.247-0.456c-0.233-0.469-0.49-0.925-0.76-1.383
		c-4.004-6.872-9.446-12.304-16.307-16.307c-6.862-4.004-14.342-6.006-22.431-6.006c-1.008,0-1.991,0.036-2.972,0.094
		c-0.131,0.013-0.259,0.013-0.388,0.024c-0.374,0.023-0.749,0.058-1.122,0.094c-0.61,0.058-1.231,0.128-1.827,0.21
		c-0.304,0.047-0.608,0.094-0.912,0.141c-0.762,0.116-1.523,0.257-2.261,0.42c-0.374,0.071-0.737,0.165-1.1,0.247
		c-0.42,0.103-0.831,0.21-1.24,0.315c-0.82,0.223-1.627,0.467-2.423,0.738c-0.27,0.094-0.539,0.188-0.809,0.281
		c-0.526,0.199-1.054,0.398-1.582,0.608c-0.374,0.152-0.749,0.304-1.122,0.469c-0.036,0.011-0.071,0.034-0.116,0.047
		c-0.762,0.34-1.512,0.702-2.261,1.077c-0.387,0.199-0.771,0.398-1.147,0.597c-0.387,0.21-0.773,0.42-1.148,0.644
		c-6.872,4.003-12.302,9.435-16.307,16.307c-1.417,2.424-2.575,4.929-3.488,7.517v0.011c-0.154,0.433-0.294,0.867-0.433,1.31
		c-0.011,0.024-0.011,0.06-0.023,0.083c-0.095,0.317-0.199,0.621-0.294,0.949c-0.07,0.233-0.128,0.467-0.186,0.702
		c-0.082,0.234-0.141,0.48-0.199,0.715c-0.129,0.526-0.257,1.053-0.375,1.58c-0.023,0.094-0.036,0.175-0.06,0.268
		C9.4,44.323,9.33,44.685,9.27,45.036c-0.045,0.293-0.103,0.574-0.15,0.867c-0.06,0.293-0.107,0.584-0.141,0.878
		c-0.024,0.222-0.07,0.456-0.094,0.691c-0.105,0.807-0.175,1.627-0.233,2.458c-0.024,0.233-0.037,0.467-0.049,0.702
		c-0.023,0.433-0.047,0.867-0.058,1.312c0,0.116-0.013,0.233,0,0.351c-0.013,0.152-0.013,0.317-0.013,0.48
		c-0.011,0.163-0.011,0.328-0.011,0.492c0,0.141,0,0.281,0.011,0.422c0.013,0.116,0.013,0.233,0.013,0.362v0.047
		c0.128,7.761,2.12,14.972,5.982,21.598c4.004,6.86,9.435,12.304,16.307,16.308c6.86,4.004,14.342,6.005,22.431,6.005
		s15.569-2.001,22.431-6.005c6.86-4.004,12.302-9.448,16.307-16.308c1.417-2.423,2.565-4.941,3.477-7.515h0.034
		c1.675-4.717,2.529-9.681,2.529-14.914c0-0.351-0.011-0.691-0.024-1.041c-0.034-1.815-0.186-3.595-0.432-5.35H56.227v4.26H93.69
		c0.045,0.715,0.092,1.417,0.092,2.132c0,5.186-0.97,10.15-2.867,14.914h-0.036c-0.105,0.281-0.212,0.55-0.328,0.831
		c-2.13,5.01-4.999,9.319-8.615,12.923c-3.606,3.618-7.915,6.487-12.925,8.617c-5.01,2.132-10.266,3.197-15.745,3.197
		c-5.491,0-10.735-1.066-15.745-3.197c-5.012-2.13-9.32-4.999-12.936-8.617c-3.605-3.605-6.474-7.913-8.604-12.923
		c-2.132-5.01-3.197-10.255-3.197-15.745c0-5.479,1.066-10.735,3.197-15.745c2.13-5.012,4.999-9.319,8.604-12.936
		c3.616-3.606,7.924-6.474,12.936-8.604c5.01-2.132,10.255-3.197,15.745-3.197c5.479,0,10.735,1.066,15.745,3.197
		c5.01,2.13,9.319,4.997,12.925,8.604c3.267,3.265,5.911,7.082,7.96,11.484H94.602z"/>
            <path fill="#0088FF" d="M106.544,52.13c-0.071-4.905-0.713-9.108-2.167-13.779H56.228v4.261h44.919
		c0.807,3.43,1.158,6.813,1.158,10.653c0,5.14-0.901,10.934-3.909,19.024c-2.295,6.181-6.065,11.274-10.455,15.663
		c-4.378,4.379-9.589,7.857-15.652,10.443c-6.052,2.588-12.398,3.875-19.022,3.875c-6.627,0-12.972-1.287-19.025-3.875
		c-6.063-2.586-11.285-6.063-15.665-10.443c-4.378-4.389-7.853-9.598-10.443-15.663c-2.586-6.052-3.873-12.398-3.873-19.024
		c0-6.626,1.287-12.97,3.873-19.024c2.589-6.065,6.065-11.285,10.443-15.663c4.379-4.379,9.602-7.857,15.665-10.443
		c6.053-2.588,12.398-3.875,19.025-3.875c6.624,0,12.97,1.287,19.022,3.875c6.063,2.586,11.274,6.063,15.652,10.443
		c4.391,4.378,7.866,9.598,10.455,15.663c0.257,0.608,0.501,1.218,0.725,1.826h4.507c-0.315-0.948-0.668-1.884-1.066-2.82
		c-0.081-0.222-0.175-0.445-0.268-0.668c-2.82-6.566-6.603-12.233-11.355-16.986c-4.755-4.753-10.42-8.545-16.987-11.368
		C67.384,1.417,60.499,0,53.277,0c-5.408,0-10.641,0.796-15.687,2.376c-0.841,0.27-1.674,0.551-2.515,0.856
		c-0.844,0.315-1.664,0.644-2.494,0.995c-6.569,2.822-12.234,6.615-16.987,11.368c-0.375,0.375-0.749,0.76-1.077,1.147
		c-0.375,0.387-0.726,0.784-1.088,1.184c-0.925,1.017-1.781,2.072-2.576,3.16c-0.409,0.55-0.807,1.1-1.205,1.674
		c-0.387,0.563-0.762,1.135-1.135,1.721c-0.398,0.585-0.75,1.171-1.09,1.768c-1.064,1.803-2.025,3.7-2.892,5.667
		c-0.116,0.222-0.21,0.445-0.304,0.666C1.603,38.691,0.21,45.093,0.045,51.766c-0.01,0.129-0.021,0.259-0.021,0.388v0.141
		C0.024,52.61,0,52.939,0,53.265c0,0.328,0.011,0.644,0.024,0.972v0.011c0.116,6.873,1.509,13.428,4.202,19.703
		c2.822,6.568,6.615,12.234,11.368,16.987s10.418,8.545,16.987,11.366c6.566,2.811,13.462,4.226,20.686,4.226
		c7.221,0,14.117-1.415,20.685-4.226c6.568-2.82,12.233-6.613,16.987-11.366c4.751-4.753,8.874-10.291,11.355-16.987
		c2.436-6.532,4.274-15.547,4.274-20.686C106.568,52.879,106.555,52.504,106.544,52.13"/>
        </g>
</svg>
)
}

export const RestA = () => {
    return (
        <svg  version="1.1"
              xmlns="http://www.w3.org/2000/xlink"
              width="30px"
              height="30px"
              viewBox="0 0 25 25"
              >
              <image
              width="30px" height="30px" x="0" y="0"
              href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPAAAADwCAYAAAA+VemSAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
              AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAp
              ZUlEQVR42u2deXgUVbrG384KGCABAsGwhFXZohIEIiCgoKKAqCAKLriOy9VxnXHGZZwZt6vyiI7C
              qDh4lUEdGOWCbHNhRECBCTvInrCHJEAIIUt3ks65f7yWHUI6qa6lq6vq/J6nniTQ3XXq9HnP8p3v
              fJ9HALMBxAEQkEgkdsEDoMIjpHAlEtsSBcBndSEkEokmfFFWl0AikWhHClgisTFSwBKJjZEClkhs
              jBSwRGJjpIAlEhsjBSyR2BgpYInExkgBSyQ2RgpYIrExUsASiY2RApZIbIwUsERiY2KsLoBlDBkC
              pKUBAwYALVoAHg/g9wOHDwPbtwM7dvBnOOjYEejcGRg8GOjeHYiOBkSNU55RUUB1NbBpE6/Nm4Gi
              ImPL0KIFcMklQN++vJR7Kij1s3cvsGYNkJMDHDoUnvoBWKakJCA+PlCeqiqgpIT1UVYWvrJEEgLw
              CjYX91xPPSXEiROiXjZvFuLuu80vS58+QixcKERlpVCFzyfEhx8KkZJiXBlSUoT46CN+thoqK1nm
              3r3Nr58WLYR44w0hduwQoqzs3HJUVwtx9KgQs2YJccMN1rer8F9e9wl49Ggh/H51DXXXLiHGjjWv
              LElJQixZoq4sNSktFeLjj40rx8yZ/MxQWbxYiMRE8+qnWTMhPvlEXVl27hTiyiutb19SwCZfK1eG
              1kjnzOEoYEZZfv97IXJzQxeOEELs3y/EZZfpL0O/fvwsLeTm8hnM+q6mTDl/1K2PNWusb19hFrD7
              jFhXXBHa66+/HujTx5yydOwItGmj7b2JicDkyfrLMGkS15ZaaNOGz2AG8fG0CTRurP49gwYB/fqZ
              U54IxX0Cjo0N7fXNm5vXSBs3prFIC1FRQFycMfXh8WgvQygCC4U+fYDMzNDf98wz5pQnQnGXgG+9
              Vdv7Bg40pzxCWFcXRpXBrGdo1IiW8VDp3duc8kQo7hKw1qlwz55Wl9x9NGrEZUKopKVZXfKw4i4B
              a+nRAeCCC6wuufuIiaGIQ8Vl35W7BByj0W9F6zpVoh1Z56pwVy2Vl2t7n1bhS7RT0wtMEhR3Cbh5
              c23vi462uuTuQ47AqnBXLWkxigByBJZELO4SsBajCCAFLIlY3CVgrUJUTsBI7IGLvi93CVjrusoI
              jydJ+MjIsLoEYcNdAg7VjVJBCjj86DFipaZaXfqwIQWsBhdNySIGrTsGANCqldWlDxvuErDWkdQs
              h31JcPQIWKvHnQ1xl4DlSGofpIBV4S4Ba91GkoQfrXv2et9rM6SAJZFJs2ba3+uiAw3uEnCTJlaX
              QKIWPVNoF3XU7hKwi3pm26NHwC7yXXeXgOV+rn1ISND+XilgyXloDfwm0YaeHQMpYMl5dO5sdQnc
              hR4RSgFLzkNr+FeJNvS4Umr1uLMhUsBqadnS6hK4Cz1HOKWAJefhIv/aiECPgF1krJQCVouLvHsi
              Aj0idJHLrHsEfNFF+t4vBRxepIBV4R4Bd+2q7/16HAskoaNHhNITy4G0bavv/VLA4UUKWBXuEXBy
              sr736/EMkoSOnjPYLjq/7R4B67Uiu2hdFRHoqW8XRRF1j4D17uO6yLtHYh/cI2C9U2CZKUASgbin
              Verd3JcjsCQCcY+A9QrQResqiX1wj4D1ClAKWBKBSAGrxUUO8pZz4YVWl8A2uEfAegXoIgd5y+nS
              Rd/7PR6rnyBsuEfAegUoBRw+UlL0f4ZLAjC4R8B63etc5J5nOa1b6/8MKWCHoVeAcgQOH3q95jwe
              YzoBG+AeAev1j5UjcPgwIoCgSyKoSAGrRY7A4UOv+Dwe1+RHkgKWRB56gyd4PK4JwOAeAUtHDPtg
              RAYNPbmVbIR7BCyxD3qXKx6Pa/JgSQFLIg8jDo64xHNOClgSeRghYJcc/3THU0rshRH2Cpcc/5QC
              lkQeeqe/Ho8UsONwkYO77TFiz90luw7uEHB6ujGfoze2tJHExDjXO8yI55JGLAfRqZMxI7DeY25G
              IQSweTMwZ47VJTEHIyKAukTA7phnGOXYHgkpRg8dAh54APi//zPm84qLAb/f6qc6F70jsMcjBXwe
              d94J3HYbMxwkJgbM9CUlQGkpUFAALFgAfPyx1c90PikpxozAVgrY5wP+8Afgv/9b2/uTk4EbbwSu
              uIIzkkaNWCdt2kRe1gkjnDAifXlxzTW8MjKApk3p6qt4oAkBVFbyO/f5gMJCYMsWYN484D//Oedj
              6hbwgAHAxRcDo0cDw4erdy4fPRr46CPgzBlg2zZgxQrgu++As2c55bOKFi2MEbAVDvJ+P7BuHTB4
              sLrXp6ezw2rXDpgyhR1uJK3d1WCEFToSAvHHxgL9+vHnTTcBAwcC3bppO6wxciTw7LP8/cAB4Mcf
              gb//vZaAx4+neCdOBNq3117w5s2BIUN4/e53wJ49wP/+L7BrF2++bl14K9Iox/ZwCtjvZ8f39NPA
              3/5W/2v79gV69WJvPmQI/3Y7Vo7AV10FdOgADBpE4bVvb6xjSadOQKdOqJw8+WcBjx3LG02cqD+H
              UG3i4zkqpKdzZN6yBfj+e2DfPmD27PBUaOPGxozA4TrhUlLCGcuVV9b/uuuuAy65hA1m6NDIGHUi
              BSvq4t57gR49OGvt3dv0MsQCiMFrrwE33GDcVkt9NG/OhjZ0KJCdzQZ65AiwejWwcqV59zVqT9Ds
              I4nV1UB+PvDZZ8BzzwV/3YgRXK5kZgKXXeYag01IhHMEfvxxTo1vuglITQ3rY8aIZ5+FJ5QGXlEB
              eL1sbADF0bhx6J4vXbrwKi5mg9yx45d5veEY5ZVj5qH+khIuMd56C5g7t+7XREUBU6dymXP55fo6
              JiH4PUZF8bmc5ugSDl/oJ54AevYERo2izUErPh91VV3N78XjYfmjojiK1/M9xzTYCKqrgdOngdxc
              oLwcyMvjKFFVxZtdcAEtmUlJ7PVat+ZaUe30oVkzjsSDB/PnqFHAxo3AO+8YV9FGjcBGNwpFNPn5
              wOLFFO+uXXW/9te/BsaMoSEklPOyfj+/v7Iy4MQJWjeFCPzdvj1HcZccvzOEO+6gBXnIECAtLbT3
              lpaysy4o4O9RUcCxY1xe+nzUm8fDTjU2lvHBEhM5CCUnU2c/L+UEgJig/W5FBY1P+/fT8DRvHrB2
              bf2FS08Hbr4Z6N6dPVJKChfcagQUFcV1Q8+eXPyPGQNs2gS8/jpw6pS+Co+NNWaEMdq/tqSE9fvp
              p8CrrwZ/3bff0kiltrGUlwMnT9LOUFQE5OSw45069fzXvvkmdxykgBtm+HDgnntoWb7oIvUdekkJ
              cPAgv5ODBzkYLlrEGWcoPPII9fGzEQvdutWxjeT3Az/9BGRlseHMn6/+Btu28VL41a+4TuvQgVsZ
              aizbUVFsqGlp7BBGjKDha8oU7RVv1BrRaP/alSv5bB99VPf/P/NMYGdADadO0fiVk8NLzZ5xo0au
              OXqni7/8hbabbt3Ura+rq2nn2bePol25MvjSSC3Tpwd+v/NOeEaOBIQQXqFw8qQQ06cLMWGCEJxo
              GXN17y7EM88I8dFHQmRlCVFRIULi7Fkhdu8WYv58bfdfvVoIvz+0e9bFDz8YWy/1XYsXC3HsmLpy
              HTkixDffCPHHPwrRrFlo93nvPSEKC7XXyf/8j7HPPWCA/u9JCCFOnzamPA89JMS6dfw8tWRlCfHB
              B3yvue3EGxhSsrKAL74wdu2psHcv8Pbb/P3GG4H+/TkiDxumzs0xIYFTlrQ0uhLu3cupn1p3QsXr
              SC/hsPbedx+nSunpDY/4J0/SWWb1amDmTK6hIon4+NDLpMcYVBO9s4rLLmMbS0/nOlTN561dC6xa
              xanxggXGPEdD+ITwii++EGLEiPCNLoAQrVsL8eCDQkydKsS+faH1rhUVQhQUCLFxoxC/+lXD99q2
              TYjqav29+ubN5tbJp58KkZen7vmXLRPivvuESE3Vd08zR+BRozhzCqU8b7yh/3sqLRViwwbtdfL5
              55yNqp0p7tolxG9/K8Q114RXQ4A3Jnb2bG5AV1aGp8dQKCgIrP1WrKBhYPRoehE1ZCyKjaVFrlUr
              juyPPw588MG5a4SaGOXIYdbG/OWXA9Om8WdDo3xuLn2iN2zg+jmSiYnhzGn7dqBPH3Xv+eIL1nNm
              Jncomjal/0CTJoF2UV5OI2t5ecCqm5fHWeTRo9wey8sLvbxvv80ZUOPG6r7rggLOWLdsAZYutaaO
              RXKyN8y9RvCrZ08hxo/nWre8PLRet7KSI3ldI/LRo/p7dSGEyM42/pmfeUaIw4fV3f/zz4Xo1cvY
              +5s5Al9/feB1Bw4Icdtt6svVp48QGRlCXHGFEMOHc3S77jpeV18txLBhQgweLET//kJceqkQXbpo
              r4Pf/EaIEyfYhtRQXi7E++8LceWVVmvGCwFEjoBrXx9+KERZWeiN6sABIaZMCXyOngZak2PHjH2+
              zz5TN7UvKzOvjsMlYCGEKC4WYsYM69uVcj31lBD5+aE975o1VkyVbSpg5XrxRa5JtAhu1CghTp3S
              L14hWAYjnmfyZCH271d3zwULzK3bcApYYcMGIa66yrr2NHVqaFZlIbiWHz/eei3YUsDK9cQTbPgV
              FcYYpUJF70h45ZVCzJyp7l5erxD3329+nVohYCFoaPr0UxpPO3c27/m6dOE0/OmnhVixIrQZnd8v
              xPHjQrz5pvVtP4iA7RWRY9o0XtdeSxN/5840boTLEUGrg/ywYfQye+GFhoMCVFZy83/CBGDnzuCv
              GzGCxpo1a8Lz7Fqoz3DYpAlw9910VFm7Fli2jB5/RUU0ampl8GAaN1u1oqFz+HAaSEPJeFhVxUP0
              K1YAkyZZXYv1Yi8BKyxbxuvqq4FXXqF3jOIvaiahWrJvvZV71xMmsBE1RHExz03fdVfw13TuTKv7
              gAE8ABLJAvb52MnU1/FdcAE7oxEjgOPHea1YQctyZSWFVFFBD8GqKnbW0dGBq0mTQESRpCRa8lNT
              Q/dRBng/xZvt+uutrj1V2FPACitW8Bo9mtEKunVjz2vl8brRo+kE0Lo1z+n27Nnwe3w+uttNnw68
              917w191/Px1hrr+e2ycbNlj3nGr47jtus9xzD+ujoZlS27a8lIAE1dXcqqmooHj9fnaiMTEUb0wM
              nXyaNtVXzspKHijZtQt48km6EtsFW62BG7omTBBi6VIhDh5UvyUQKg2VYf58WlvVcuKEEF9/LcSQ
              IfV/7qxZ5xq+Cgu5fo3kNbByXXONED/+aNxugFGUlQmxd68QS5ZwO8rq9uv4NXBDzJ3La9Ik4Pbb
              OfqlpYXXWb9rV3UjQkUFp8CLFgEvvRT8dbfdxrXiddeF7xmM5l//4jV1Kpc9nTpZl/6zspLHKAsK
              gN27gT//uX5bQ4TjLAErzJnD6557gFtu4foqXCFWlEAH9bF/P7B+Pf2X64tE8te/8sypmmm4HXj6
              aU6PH3iAPsYdO4YngkV1NdfWublcquzYAbz7Ls/g2hxnClhh1iyKZciQyIgXlZ3NsKCrVlGcwejf
              H3j5ZZY7IcHqUhvLpk3Aww/TXnHrrYFzzqmpvIwyRPr9/O737AlEO1m6NPLtBiHibAED3L6x8ryr
              EJyqrV3LxvvBB/W//oUXuLWSnu68MDc12bcvEMRg+HCOzJ0787r4YnZcLVuqq4PSUo6mXi9H2X37
              aBjctCky45QbiPMFrLYRGIUSN+voUeCHH4DDhzll++yzht87dy4PjRsdGTTS+e47XgBH5KFDaUdo
              1Sowc4qNpdXZ7+c6VlmqeDzcaioq4uGG/fv17SPbDHcI2MgRuH//86Ljn8O0aYwJlp8PLFxIY0lD
              TJ7MmFeXXGJu4Dw78NNP9trGsRjnC7hpU2NH4IaMLvWtbeti5kxOmZs2laFtJCHjfAEbFY1Dwcjp
              7cyZPH8qkWjE+V1+dLSxAtaS1yYYeqbLMTGRYVmXWIrzBWz0tNTI/EhCaHuf18vY2WZms7AKI7Jf
              tG8PzJhBv3KH4/wptNF+0eHKj1Sb6mruZy5bxv1TIz9Xa0diBmVltCSfOUPrcnY20+8UFfH54+Jo
              L1BCKiUl0S7RrBn9rWsucQ4etPppTEedgNu3Z7D2pk15eqRRo8CX7vWy0n0+uqhF2kZ5TIyxU2i9
              jvOh4vfzlNLq1TzIoIUBA9jQo6JYH7GxXFqUlfH7jLT95prpUEeO1P45kWYU7NuXnU5cHDUUH3/u
              Mqi6mi62FRXcKist5b52Pa6ewQU8ZgwrMimJP7t35/ovKYmb7IqAz55lb1lWxptt304hFxXxfKdV
              wb4UjN6WCWfSrMJCehCpzQ0MBFJbtmnDxtG8OU9HJSdTtEqjiY1l5xAfb51fcm2Mzg5h5am0Fi3o
              wtu2Les3KYl73CkpgWTeTZqc62lXVcW97PJyDozFxfQj2LuXxxwLCzk7+fe/f3nLuQJOT+dxuB49
              eF18ccN5eJKSAoele/ZkoQGKODubKUsPHWJBGspzawZGJ+4y+8wxwC/u2DFGn1QTzf/229k4EhLY
              y7dvz/jKRmeSMBu1GSgilR49mOkzNZVCTU/n78p55YaIieEAUTP4wKWX8qcQjLS5fz81tXcvMHXq
              zwLu0oWHxHv04IFoI9Z5ycm8Bg7k6HzkCCNp5Oby5jNmhKdS4+ONFbCZ07KKCgr322/5fdTHlVey
              sXTpwtlR9+72t0obFdQ93Dz5JMWaksLZTkNRV7Tg8QTOSw8ZAuTnw3/FFYjBm29StAMHmjc9bNKE
              8YEvuojTg6NHeazsyBG6G86bZ17lGr0PbNaolpdH+8G77wLLlwd/3ZQpnCZ37cpRN1Kmv0agJktH
              pNC/P42JnToBGRk8WRVO2rSBuPlmxFTfdx+ijNwaaYjGjXkSpVs3rpNHjOB5102bmKXvwAFj72d0
              8i4jp9CxsTRWbNnCVByvvBL8tZMm8eralaOu3abHamjVyuoSNMyoUVyyXHQR23AosbYMJgZAjCcU
              8SoGK78/8G/R0RSlFgeHxERevXszZtSYMVwvz5tnXKJvow0jRgpn82bW5ccf84hhMBYt4hS5Sxd9
              s4mSEu4WVFbyvtXVtKo3axYZFlsjnWSM5tpr6TXXuzePP+rZrz57lhbmmocyFDweduw1Y33VQ0y9
              zUGJ1bRnD5NEFxQwoVbNNCyxsWwEbdrQ4JWayiNhbdqEZgFOSeHVqxfXEQ8/zAb+2ms8jK0Vo63Q
              Rn7eW2/V//+ff87pWbduoXccx47RarlvHxuMkuhbsXD6/fx+Bw/mLCgSpuJqjT3h5IormOb10ku5
              /gx1mVlayiXj4cM84OLz8XtQjj/WFLAQHBDj4vh9tGxJIXfoEDgzXasDr7tVFBVx33HzZo6I336r
              7lQNwKNgyoI+LY2W6T591E894+K4nujQgb3djTfyPO3772vL+Gb0Hmc4tiaefRa4806OuGpnED4f
              lx/r13PL4cgRfmdz5tT/vnbtuH0RCRhtg9EzW0pMpK/6wIEUUihlO3mS26lbt3JH4eBB5s3euFFb
              WUaMCGxBZWQAgwYBTZqgGrUFXF5O97NVq4Dvv9cWK+j773kB3NYYMYIjSJcuTFiVnq7uczwe9sjN
              mwese6dPA0uW8OidVZhp6e3Vi6eZevVSv7YqKGCa1Y0bOVP58svQ7hkbGzmOHEZO46uqOHPUwhdf
              sN0mJIQmXCWt6NmzFPDq1cY8y/LlAcPmgAHsVDIyEHXHHYgRADwAsG4ds7NlZXG4N4KSEmD+/MDf
              I0dyZO3fH7jmGvWxe2NjA8G627Xj6LRqFTBunDHlDAWzBDxtGuNHp6Soa8jZ2cA//8mzyT/+qG+Z
              ESno2b7My+PIt3EjY2VXV3P2GApffsmQvfHx6pdKZ88CixdzYMnONj9O9/r1vFq3Blav/nkEfvFF
              RowwSrjBUBJyf/oppwJpaYx1nJmpPvZT48a8xozh/vLBg0xTOm1a3a832s/XaAGPHAm88QbXWGqE
              e+IEYy0vWsRpWSQTFaUuyJ/C00/TdyAzk/VxwQUBV0+Ph7aXigpOS3NyOEXNz+d7a0bkKC4OrZxf
              fQWMHRtwMVVDYSHwySf0NNy6lcuWcFJQQOOn6NcvMuJCP/aYEDk52mP8FhYK8c47539ubq6xsYTz
              84175nffVZ9G1e83J0ePmXGhb7pJCJ9PiBtusL591XUtXMjyhdrO/vAH68v+c1zoyAzs/tln2jMK
              +nxCLF8eyCR37Jghuv2FM2f0P9/ddzP4vFr+8Q/z6jpcyc2WL7e+XQFMkLdlS+jPWVDALJlWl98W
              Aq5Z2T/9xEx2VVWhV/qOHUKcPau9cdaF16v9eW66iRny1FBVxTzHZtdxOLMTFhXxfgMGCNGmjfnP
              1r49MxOOH8/MFqE+Z1WVEHl5dc/sIuOK8MwMSjZCgE79w4ZxW0Xt1kqvXsaXSYjQXp+SQrP/6NF0
              g2yI6mruHX72GfBf/1X/axMSaCi0C82bA489xnpYtoyRKPPyuOWVlaX/8/v3p/9Bs2as9yFD+G9t
              24b2ORUVXNN++y3w4INW11q9RLaAazJhAn+++iqDwLVowQZh5ZGx+hg2jIa6Sy/loQM120IlJdx+
              eOop7goEY+JE7q8fPMjg9XajaVN+h+PH08lh9276gXu9NA6dOUMRCUHDVFkZf2/e/NzEZk2asF7j
              4nhlZvIkVkqKtnPbpaW8/3ff0b3XBthHwArPP8/r+uu5H9yjB8Xc0LHHcPHoo7Su9+/PBqWmg/H5
              uA301VfAc88Ff93QoRTv8OHcjpszJ7IFrGZ/uV07Xsox1KIi7vdXVHA2opyPFYIjqyLg2Fh+561a
              6fdPVzyjliwBHnnE6loLCfsJWGHxYl6tWzNplnJovVUra/16p0xRlwsYYKPMz+doO21awAGmLqZO
              ZaegHO5X3CIjGZ+PVyhbb4p/vNkoKUVPneL25rPPWlVLurCvgBUKCujYATDT3MCBPLHToYM1Qlbr
              uXP6NPcPFy2iA00wfv1rOr0MHRo5swy15OTQOSIjg99JOKOZBOPMGa65c3KYMbGhVDcRjv0FXJMX
              X+TPRx7hmdl+/Tg9C0cUDYWGjFxeL48Prl3LtW4whg4FnniCa2gt2eYjgZwczkguvZQHArp3p1tt
              OAMDCsFO/vhxHvA4dIgGwvXrra4dQ3CWgBWmT+c1ezZw883GhCpV0Oo3fOYMfct37OC6de3a4K99
              5x2uc52S4GzLFuCOO9ihTp7MwyppaTQ4mXEGOC+PLo6HD1O82dl0N12yxOqaMBxnClhBsWQaTVqa
              +pClx49zbXvoUGDrJBi33MJjlBkZ1oWvNZMNGwJRS2++mbmgLryQ22EdOwaOoCrRT4OdJvL5AtFQ
              vV6KNTeXYq2q4qksZRvoyBGrn9pUnC1gs07adOoUXMBC0F957Vo2nuxsjqgNMWsWp/1G5siNZL7+
              mpfCqFG0WzRqRAEnJJw/cxKCAvV6KeKSEhryioq4/ebCpGjOF7DReDz1By37y194X7XHMR99lM4C
              3boZO9W3Gw6c3oYDZwvY6KDuCvWFIZo5U/3nTJ8O3Huv8aFvJa7B2QI2Qxgej3GxmxIS7B8KVmIp
              ERDJzETMErBRBiYzDGwS0r07Ax4sXGh1SUzF2SNwfLw5zhxOtBBbTe/e7HA3bQrtfRdeyOAOQ4cy
              mGLHjvSFVti92+onMxVnC9jomNAAR2A3G5vM4q67znVnLCykldnv53ZgdDS/z7g41r/awwpOjJ9d
              8/GsLoCpmCFgwPhQtRJuD1VWBnYOWrQwJhezw42Dzl4DmzVSOrxRWEKrVrJeNeBsAZs1fXKDo0W4
              SUiQAtaAswVsFlLAxiOXJZqQAg4VJXeNxFhkp6gJKWAtONyyaQmRkFzNhsha04IcgY3HrIMnDl9X
              O1vAZjUI6f5oPGatgR0+sjv76czqfaXBxXjMmtV4PAx86FCcK+CLLjKn95UjsDnEx5vT4UZFneta
              6TCcK+DUVPOmT1LAxmNWwLuoKONOj0UgzhVwcrJ5WxOREF3RaTRqZM4IHB3t6MMnzhVwy5bmCVge
              ZjAes+o0KkoK2Ja0aGGegB1u2bQEtfmuQiU6milZHIpzW2Lz5uYJ2KiD+Ho+R4jQkmcHo7pafzmM
              wEy/dbsFxA8B57oUNW5s3kgZFcUIkqFmvatJaSlz/WjF4+H79cS9Li/nZ+hZezZrBowbp08kubnm
              bfl5PI7e9nOugKOjzWsUTZsyXIuezxdC395nYiITnY0bp72jqq6mRV3P+vOaaxiEXm9daMkmqAaP
              x9F+1lLAWogEw4jHE1quZLOww/TUwTYL5z6Zg3tdSQg4fAR2roDNigktsRcej6NPjzlXwEZYaCXO
              wMEduXMFrEQ0lLgbOQLblKVLKWKJu6mqYohah+JcAS9aJAUsAYqLmYPKoThXwACwdy/TUErcid/P
              NLC7dlldEtNwtoC/+UaOwm6mrAzYvNnqUpiKRwBeAM494JqXV38+X4lzyc939GF+AD5nj8AAsGUL
              DRkSd+H3A3v2WF0K03G+gH/4gcmxJO7C5wNWrrS6FKbj/Ck0AJw86eiwKpI6OHWK+ZacjQum0ACw
              b59Mpu0mqqtDzzNsU9wh4FWrpFeWm/D7gSVLrC5FWHDHFBrgdpIdjr5J9OOO6TPgmik0ABw/bnUJ
              JOFi/XqrSxA23CPgZcusLoEkXMyebXUJwoZ7ptCANGS5gRMngNatrS5FuHDRFBoAjh61ugQSs1m1
              yuoShBV3CdhlX64reeMNq0sQVtw1he7QATh0KPz3PXKEx9oqKhhgLSGBqV/0hJWVnI/zfZ9r43Nu
              qIK6OHyYh7tbtDDn83fsAObNo+dXYiIjQZSWAtdey+iRQjAG8qxZQPv2wCWXAKNGAZ06WV0zzuD7
              760uQfgRgFewabnjWrlSmIbXK0RhIa/HHw/cs7hYiIoKIXw+IcrLhcjJEeKhh/h/w4YJsXq1eWVy
              EwMGWN++wnt53bUGBoDf/c68z46PB5KSeNUMVB4byysujln4OnUCfvtb4Kab6HA/ZAhPTUm0c/Cg
              q/Z/Fdwn4LVrzT9eqPSPNf+uTWoq8MADgb83bOA6WaINlxoo3SdgwHxHdzVhTGNjaVRTyMnhetkO
              +P2Rd8Z61iyrS2AJ7hTwl19aXQJSM3Z1WVndsayrqgKX3x+4av678n/1xcL2+ZjMrLKy7v9//nng
              xx9ZjvJyXl5v4FL+zs0FXnkFWL684ecLl+PMTz+5dvbiLiu0wvbtVpeAYqsZ7rRTp7qTjK1dS++i
              6GhuQSmi8HgoWo+HV1UVU6pmZp6bL6myEsjOpivpjh3MqpiZSW+l6Giu2wFazQcN4vZWdDTPT//0
              U+BzevUCzp6lJR8AFixgWYLNNkpKuC7t3dv8uvzhB2DbNvPvE4G4U8AFBZxG9+1rXRlOngQWLw78
              3bkz94dr07YtDV/5+cCYMfy3GTOAq68OTLnLytiIf/MbepvVFPD8+RTv++/zc7ZtA959lwa1ceMo
              5pwcXrNnA/36UcAJCYH0qXfcAbz5JkfhvDxg7lwajPr25Vq+NsoovW0bsGaNufUoRHjsGhGKOwW8
              cyeNRuEScO1RqrwcWL2aogCAZ58FLrus7jy2Xbvyp9JAMzP5b926nfu6fv2AY8fOnUYfOgTceitw
              +jTFCwDp6cB99wG//z0weXLgdZmZwXMNN2sWEHPnzrz3J5/wfXUJeMYMXlddxU7GzGOcW7e62kXW
              nWvgqiqOWOE65F9Wxinl6dMcnZYsAcaPZ17dd94BHn0UaNeu4TIrP+sqd1wcMHbsuWJRDDu1R6fE
              ROD22wMOLR07ArfcEjxPcO21bHIyLegXXlh3vLHMTP48cIAdlZls2OBOB46fcaeAAYopJyc899qz
              h+vJjRuBP/+ZYmnXDrjrLuChhyggPeTmAkVFHPFqepkpo/GXX3IKrpCUBAwcyJ8AR9VQR8mWLYG0
              tPNnDV4vkJUF/PGPFLAaY5dWvF7OpoIZ5lyAewW8dSsFFQ7atOFUs2tX4OKL+W9NmtAyXFYW2mcp
              Rqua/Oc/dft4T5zIn489xvQiy5dzXRoVRaOZGVPbFSuASZOA227j38eOmZebaPt2Zt9wMe4V8OHD
              4fN+at+eI25aGqeuo0ax4T3/PPDvf4cW9lYIbpkUFPCQxP797Ijq2rLp1YsGJwD405+AkSOBF1/k
              1Hr7dnNSsM6cyc5B2ePeto3LFTPYuZM5sFyMewUMcBqdl2f+fWpO8ZKTgZde4u+nTgEffxzatlZB
              AcW/fTstvDNm0OIbLAv92LFcJ374IV02FyzgmvuFF4zfetm3j51TTAyNZi+/TJFt3Wp8nRYXs/Ny
              Oe4W8Pr14ZlG15zyejy0OP/97/z7X/+iCNUec0xO5jT88su55n38ceC114Ib5OLigIwMTmnnzKHo
              J06kkL/+2thYYX/7GzBhQuDvRx7hzyNHjO8od+50TejY+nDnNpLC1q0Bx4RwEh8PDB0K3HsvG/0n
              n3BLa/JkOmPUR3Q0D0o0axY4T9yuXfB90M8/B+68M/D61FS+PzaWBrWxYwNbRHo4fZqdQ1FRwLCl
              WLW/+44j87hxxtXhoUPn7qO7FHcLGKAluqAg/HGUUlOBBx+kgAFOa/v2BQYMqN+Xurr6/LXroEF1
              Bwc4epSN/M47A//m8QA9enAba/ZsdetvNS6RX3/NvWXFEQTgrCAtDXj4YWMDKZw86Yq8R2pw9xQa
              4FrS6LVU7dNIwQSZkUFPKYXMTGD37tDv16lTIHXM9OkcBQFahOvy+46N5RbSuHHqOq66HExqP++6
              dRRvcjK3slq04O/XXcfX5OQY53Cxb58r8h6pQQp42TLj94M9noDnExBcADEx3I99+eXAv/XsWXdO
              W2VU83i4DVT7fh4P17Pz5wdGaGXvt3aY1ZMnuR6eMiXg6VUfDTm8rFrF561rW6pVK+4zv/cejWlG
              cOIEp+USRL8MvAC3T6Uvu4xO9zV9iLVw8iT3PdevB/7xj0DHUFbGqWR0NEVbU4AJCdxm8noDBrUP
              PwSWLmV8p+RkjjgHD3K67fFw5G7bllP/oiLgzBmuQd96C/jiC+C557j+HDSIW0mTJgVcGrOyGEzg
              xhvpZlkXWVm0DXzyCf9OSuJIffQoy6N0SEeOAAsXAq+/zqn6wIEc3RMT+f+5uSx7fDw9srp3D8Ss
              atxY3bHL2hw/zq0jOQIDgN9dQe2CcccdbNR6Ts5UVXFbZscOGomaNg0I1efjmu3AAXYSr79e9/sP
              HKAghaAoS0sDBxYaNeK0VAi+Zv/+wKgsBDuGxERaqDMyKKSarFnDkSs5mWvgYNkaKyo4iiufB3AE
              PnuWz9S3b+C9R47QGhwVRTGWlrLTevJJ/v/MmexoWrakYIuL+f9dunC6XXOWopZ164B77tG21HAe
              PilghblzadiRRDaLFwM33GB1KSIFlwV2r4/CwtDdGiXhJTeXI7DkF6SAFTZv5vpVErnk5/Ncs+QX
              pIAV/vpXKeBIxu+ngE+ftrokEYUUcE0KClx9NC2iyc/nvrbkHKSAa5KVFZ7DDZLQOX0aePttq0sR
              cUgB1+TTT3lCSBJZVFRY47NuA6SAa3LyJPc25TQ6sigsPNflVPILUsC12b2bThSSyKG0FPjoI6tL
              EZFIAdfmpZc4Eksig4oKYNcuq0sRsUgB18brpYDNCDcjCZ3iYp6wktSJFHBd7NljnzxFTqe8nGF4
              JXUiBVwX998vrdGRQEWFcUcQHYoUcDB8PqtLICkvZ7YISVCkgIOhZOqTWMfZs1aXIOKRAg7GvHlS
              wFZSVcWInZJ6keeB6yM7m+FgJOGnvFx/hBTnI88D18uWLaFlTZAYh/RJV4UUcH0sWeLavLOW89VX
              VpfAFsgpdENs3x6eLPOSAKdOMZqlpCHkFLpB3nknfHmEJUSJhilpEDkCq2HtWoZMlZjPiRPhz5Jh
              X+QIrIpnnqGPtMR8pk2zugS2QgpYDT/8UHcsZ4mxLF3KTIsS1UgBq+VPfwK++cbqUjiXrCzg1Vet
              LoXtkAIOhccek5EhzGDjRuCNN5g9QhIS0ogVKs2bA088wfQhDeXyldRPZSXwz38C774rA7ZrQ6ZW
              0UWHDszzIw//h0Z0ND2tdu60uiR2RwpYIrExchtJIrEzUsASiY2RApZIbIwUsERiY6SAJRIbIwUs
              kdgYKWCJxMZIAUskNkYKWCKxMVLAEomNkQKWSGyMFLBEYmOkgCUSGxMFIMbqQkgkEk3E/D9nn8Fp
              8v6RugAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyNC0xMC0yOFQwNzo1NDo0NCswMDowMOe/4bUAAAAl
              dEVYdGRhdGU6bW9kaWZ5ADIwMjQtMTAtMjhUMDc6NTQ6NDQrMDA6MDCW4lkJAAAAAElFTkSuQmCC" />
    </svg>
    )
}

export const Abonement = () => {
    return (
        <svg version="1.1"
             xmlns="http://www.w3.org/2000/svg"
             width="30px"
             height="30px"
             viewBox="0 0 25 25"
             >
            <image  width="30px" height="30px" x="0" y="0"
            href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABaCAIAAADIG8SkAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
            AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
            RnRFWHRSYXcgcHJvZmlsZSB0eXBlIGFwcDEyAAphcHAxMgogICAgICAxNQo0NDc1NjM2Yjc5MDAw
            MTAwMDQwMDAwMDA0NjAwMDAKVSzSUQAAAjV6VFh0UmF3IHByb2ZpbGUgdHlwZSB4bXAAADiNlVVd
            0tsgDHzXKXoErF98HCeYt870scfvCr4kTuJ+Te0Zo4DQrlaC0O+fv+hHPlELyVV61Ci+uPjFLZSL
            s5uHr75LY9775XLpzJhfXXPGQkybFG1RVOBbfSWtsQU2msSmu6ljREARbGKWLjsXuUaVLapjo7cE
            84VL/var7yG5RokANuo9ecg2F+7ug8kjDOYuuUPvO7hY1WaFOMn1GFNivItwA58iiwBVkC/mFlGx
            tDF3xSyPdeaOcX6duA23bThucAkuLy9/pcdgAR9jVfWX1JjGYqZXQ/EW2ZBOj/HwHvDifTAOIC+y
            5ntnwvi2CQBGIYH6pCJRkRYQcv2ZBSigVCgE+zqUWqEQPG7rvhAE6wFhk9UU9liLFPid7wDbHyWC
            LQSzIZUKPiXJQ9pyq9VrSG3onr+Epee452ERsJ0El1DL1h0a079JnwfP5g7oBZ+e4ShkaMTYYDN+
            Sj2xM7ymwIYgmrWzBOh+fWvKAkZwSCcZVskGNUEbFq42zlu6oxeE1wfAUGvTrLZkvR0agYmuOqo2
            nZDOOo7nptk38EMDCuy0GD0g2fVqAk4iGBeMSjBWqeOrcHBY/oacKUhYnsDsuDdg9BWdINfvkM+B
            Zad5STyh2+hfBzp+wM6qlUOxNz9oc5OGPtDGPmFIb9ockT/Q5gZMB2T/f2346wblTt+f75vb8110
            nz1cwDSv1Ll08jdg2ZAjHZ43OP0BphJsVBtzi80AADg7SURBVHja3X15vGVFcf+3qrvPOXd728y8
            WRn2RUDWREeNLG6gYABRcCVGXGJQISSKEYiKwbjEuDDEHRCJGARFQFE2AZFFUEB0AJEZZmFglrff
            5ZzT3VW/P86bmyfyTPj315/53Ln3nO7qqm9XV1dXL4965USMSpHraR3GQQQMQAWBiQEApACAKMrM
            hOrXM5OHNQDBM0jBs1QUlkSRE4LCqdaUAMCLJLPEn5mE+v/HKOzIgBCkI1S3RAAYAHoELzCM2gw0
            AadKAIKoGIJooqQGz05fQAQiAKiqUoUqlGEAKKAaWXvdTnf72OjgAmr3xrOkZjiB7uCYAAhCVQIV
            ICoAgwiQZ6+4jLm1lisaStAqNyQABGKIqqo3FoQIRKD+7IQ0QgRsIBYAtAQlUMB2ABZlFSZyUKiA
            CWSkaj0wRwlsE1JAd0g/X6pQqdgVhUhgtlXrq3gEMsaqaF5Spz1Zb7QAVoUCMYqxpOgj8T/tLCqq
            avjZm8YgAFYBVUgM1szWrUYJdgdbAAmE/4wAkSAKRWSebdGIoMhrsQkCWKDcL6sIVACWYRABgRJI
            gABk82h6jNEYQ89SPZMCIcIYsEQAwNTkBKkvYVwFuhJEQQQCTCWqRAbRrEbSLPbPlrx65aTqICRI
            GAIIYCQQs4Kgs2QUSlCdByHWEppCodwjSiGsBIEwYQdrUAVICaoiosYYKkSIOQUQZqmA52mBfocA
            iCCY7XEBMEAoSpckIFFVEAlAKgpAqm4BACAVQyiFDe/QH0UInpkNm/lVd1olBacEQD0QQLaATUFA
            iCpRHYNA/4vy29gGNysVU3RUIyhlSkNfPIVALQcGAbZUJAoQcgIB1osa7bFpzkO/qtwHMYYrgyg7
            RGRwpRmIgCiYwGwhAuYYBYaJwCJMgIhhVkBUmEAE5whQaDm30/2x7jKBIIAKVKDoKoeEjOYqsOwM
            KzQCAQhACZlPhCYQBQVrnbRBpIgFGJbHgBTkoAw2gPGxJA49ykxeGFGXGTVkrIpEgzC/lVYQOQNA
            qoZXaOISKIuKMAXA9vVAlVQiiAUqIFIxACSi18VV/5xvH5uamoBEBlmGapQYjU2etd4Oi5ia4RR5
            10guWT3bdb+Rl74m7nwkRbACZjJ2bivz+1lmohdOO8/eVU3b0ohGgUwxqZEmhIB2GRa51Aq3bHKg
            bR6BOCQENTBPbtl60Q96D65t521AhkukET2O3cQ+K30K4pwLKkGiEEDUarUWji6qnftuDDfVUKEx
            5WR25CZYQHfoEwwBIuh1Nz/+ePzxJWWZM1FiLSSUKpZAhPLZTRASRRBYNkmMqcF4wOZHfklbNzTO
            PIAxwiD4tUXnx1LeXGNB2Yzx2Xuarx9cy94DOxq7n57ylzsMpjoq2NbwDtzpCEf/pE12gjlUCRFj
            49+47OELv7d0KmSDte3SzctQY5sn6vyzazqLeiIfI5hM4krv2xInsqz3/BUvPPkEaqWqGgGCEDgq
            GDSrbSYAGoSsNgebP7+gFXopg5wFl+yFywRSDwQTU47wwgm5NjeKgUHqYAgZAT1bz5O0Q2hbJIyF
            vbH2mjsTDFgiWCiY/QONuKAjrrQpKIILpY5wx1PhJRGpk1DaOgrN3WJjcTHwEuKVrhQTCqVYpoXE
            5VZrUX6LyDABKBJd0L7lkZanohHbaCukQfUplwxNpjjpNUt/fokccEhPHcfCqs9HR/e77+pFV316
            BokLFBLNyzalVFdne1j+/dvjgAvgGqcWwRAz4Cg8ux4CKBjeM5GwgTOwFIMEBBBbY4toxOayqPAx
            WtfEVvELAsR6qxItOhG1hKwg5sWsbVWoqmoEKbGQaleskaah0iDYmIgWxk6wMXZqA1q5oWB7a1JW
            k1gNOceRJPYgXcM9pgaiQKyoFSOseckxnSiToaSm2CqTQzoys8vggZ/+EBJNFg2vI51O7KIi2edz
            Z2KXRaObt0wPDHW2bY5R6jYNQkElM24+HOYFSG3NSDRMQYIQSk68CmXSk6JegFwtJiUSdLphyMA4
            M25apcakMzPYQtubXDgVb0IBGGg1+ERQACkgoFB36nWrhBaoQVnPR+7JqKCXzVxei08ECkXxQKpd
            FueJ1GIqmyQxzBwNIxmDaVs0AW8609Fyq7mgyNuivRVZMqF+5UffPTNUb/7w1t/+4ta6jYNtE4/4
            S/uaFxObRy68tLN9e5alZMT44FXgDCk9Z4BmirIepcGUM5ThpOQQCWCLAUH0sR1j2axlrlcK0hef
            PPqSY3Xzo499/z/K9iTFSI6NIZMywLP+ISKxQKCIIuK8B7JurTDZPjW8KeF9kXo4CbrFU491uBnf
            q2FGCGys2raVhg2UgAuZFHMopA4BU7p5ATfXJZtGhmoTKBNJVJOD913w5mMxVdx/7pfqZZ4JuWzh
            7v/6jxOc2R/ese3mexuxJNcIRccFcMrBaN7pNp4rQFmW1fOOFFoYSEZABCF6qGCGmLm0KUqT1bQ3
            ltg9T//KONVH5Kn0wZ/xb3/WVFJoFC1ibwQAgYiId8zshFRMlM3IXtVI38W1v0QCaAZYoYkOPd8B
            tQgCKJsBEbTJEampXJQio03gBbPuNAX2ZvFxr1jy7x+K2598/JjTw5NP7/T5MxHs1L9+0z6xRVKV
            qZB98LXYd+WgyCMfXr2oVO9MmXcVMInjKGDUarXnrEEmdNhjRiB7HlLb769q9SFjTMHqNNWgCcpa
            K2mjlT31622/vhVT22VoJQQgqwqjWvoQCZSm2AGQAlBSITIJUzrdfPFg40xO/wLWx2KjCYRkEfPw
            AIEC0P2Z5D+EnRZ20U+x5KUOqATi0ttGa+ADlB0QmAn5UC/RN70kLEh4wUr7ooNoIG3t+zzc9cT6
            1ZdJQ6ZVB/fdbfHfv2G6Zib/5T95/QZro3fkhI0zARSLIo9SN3jOAKmALLg5uvTIU7Kj3wlugJAQ
            CHASEC0EtRQmji+K/7TtM6e0Dnv95FNre2vuS3IghbMuFp5Df6wVAKoETZhgqVUfPp/t82OYmJr4
            mPjrBtzxif2naJtQGL43hq/mxZ3slwSqQccyGzIVSOJ1JtBiKEEcCEpN1JJ89dV00F7dRzdN/m7t
            Qdf/O1h+8ZkvLq75sVAk9YGl//g3dsVo+sTGya9eaZrRl7EkbXjkEDjTympAVHnuAPmEu12ZLrVh
            a5lJoqhBSMioMnR8xo3WAVOiTEbcTi8MN13ce/juTpDFxic1bClRc8QKp0Y1UhVIUFUlgJgS5pp1
            u0FUy5/Y/KZmGGb7MpilU4RhLTrtb5ve7Q1tRQoBm1nJlq1ess3oUEAoZ1svCKLRVDvdyTWPje12
            fDMd2P3sd+jiYUixqzHreapBjZ1X7j34hqMi4al/Wm3ge/m0ukzBKZncoAdoFCXR+Y00B0AjO6Vg
            gSBEoNCJSvBCwKJysn3ftRBjkIMcPFE51n4yaW263Tx+ezdsnwRaUmPPHS5MK8woJtKGMoS4VMfi
            iXJBAIRLDmnoJNtyW0frlA4aaN9rt3+Zwkx38E1x6Ei1YRCg9nXSvSGzC0WFQ5ZxQireeBeGo/i6
            mpr0IidKSDRlgiSOyzigdmSPXdLTjm+j1sXAsu/++97vPbN42csb/3lWXs/M7b/eevsdaWdGaq5R
            uKEu5wm1Y2lFSKURkFPUuZNw1R0fsARIhFEP62ATAQwxbNoCjEVXvHnwxuLhm8r9jrQ0TW5ALjlv
            6tovdQMksXzSv42e8N7xuiMr8E0O7ZHGID05NbNLXWa6A9bl6bSgrqxQAUJa1NPQkXIC+lArTKD4
            keCxtHFwUnujsgNUIow2S4TcsyMHDlB2LuVYV/Se3RRw1IS67XzBh090ac0JTACsjnzirSPdoPUk
            wm9cu64ETOCBDk232BbRSmwYa62dLtpkjE2S+TTIMgI5W8VpQCgVNeMGF45uWrizTIyPduxU6E5d
            8cnB815gMWB6Hq84ZXz7tuyhH4Vt08M8UdhGfUD+kGHxbkvNX7ztie98YrhpdRrshorOZMSgBlKr
            ICj3lEbFtb10VH5huhNWMtRfZ7LT1Y1oFArTzixCutLEvcuZTZaFSGJU52wMzPMYA6NBy3LAJM1D
            91Gw9Qgxn0nMcDRlPQU4jbrTq187ffcfijXrXAxDGnwvt0V0RMESxdAg7m3aNi9AQbwTCyMAK0AE
            BXOzteKYEzeNHJdtv0mvX/2HdXcO3HyzeflxoeZpxaEr/u47W793VvnDz/j777SvxYKdXr3/2782
            tXBkcGor3vZRf/s37bp1Zd5Nh9AunDEo0S4wkKQHusF/YOsNpKB6WpZEe1NzZU7gUBS9j9kwUXP/
            gvrKpryh2/tWlK0EFgmqVtGdfzCJETFtDWz99o1Lz343LGxih8scoRnqCEESYYzU9/vy2RApOSYw
            XoIhZtK2esdJWuqGK36Cy657doDsjqYRSBXK8MFPbNzYuelLzn2h21yRnvLVXdf8IRneCW0bmjaF
            R+YWHnjE1p9+pnz41gU3fjq8+qyw91G9M/ea0SJdsLx24AkDL6lvufEbG6YwlPUAT9WE2IyY2qFA
            ZjkFg7JpyEAkGJ2k6Udr+S9zfaisNW393617gzXXRf+UI2dIAZikA3n2EC1laVTdWEzol69cccJR
            +b67ZD3/wKtPrx+1aue3H5suXQSVTiwMpUTekEWAjUqOIdIwTgBMt9vbxlvzaZDORgq5Mk5MsAQE
            HyzqXjpjG+MX/45POpUPeX6BXhZrpXERHTx4x5QZGPLT/vr/qB15TL7Tfgv3POypR36lU1Mbb/jK
            6MDw4mNO4+u/OUAO4oyxAAweL9s/tL4H29HEFLFF0RuXawEX70E+YWRnbXglQBvGGCGjiADH6GHF
            zDMSFxEtm2nha+O9DWddsPz752vNpLnEc1c/cuGlppamXUmUumWRDTS3lx1r60Wna/JgAHEsTIMu
            kafHcdQLn30UUzgoALaw6gMpGJIylnWzhV0kbMenxjvf/0a444rUOzCSXl5bd1/72gsaJUTs+MZi
            7KJzmhseLuq5kzDJtuUCtm174kffWfTWj7W9g4KEjJbq15e9a/3UtdT+vo7/OExeJlPf5qmfaveG
            ckqDfThmuXMvYgLs/VHGE8qg0wSrHIKfbyaALCTex7Qok0ynr7s5/8FtPWDPf33v9Ig13d7MU0+O
            hYnxcjtrPvX0xlqnrZ3xetFpIHfoNVGm7XHf3m4XzjtZZQVCmI07smVLAFTK3kQiTyfJpmibS23b
            Tzz1jbMQYpsA1vs/80Z1M604HZ3axMttP3nqAwdP/ebnytMLO5M1hQxQ2X38kev+hT7waZAngEk1
            BuEtbAviIKVJLZuYApsjTcXamJo9rD2I+ZVRisL/pCzHDFJwx3BqnIGOzCdALbiZhPOhZL2ZGRgY
            +NXZF9qtU/Zlh+z56qO5SHbmUdOm1KfS0+iy0mRJTxJPlCQzFh2LWq2RctINYV6ADAS2gGErgKIH
            BK4bCQ1fpjqwE/XKPBAQurlObqgB/ravj0xKEDxdS9KylvpuaQtWUUUWYJijSSnqcAE7FlvrHwA7
            ZXgP9rvWtOk0jfkQN8ZtxyEpS/fa+ujt6ZJrt7XeFIePghu0M9804/czdzp2wvOoR5BSDT8d3WQa
            gNhkcqbMJKoyEFDa7tKOUleaMc1Dd8mWqSf/+cKC/cg5p27Zb8lEZ2ZpknViT1JuBZv1yDoXHReF
            b0ZbCyZXdFQTSiaH3AxmrCoCOuSgsdACYi0pG3YKgECiDFiFYTuVQqKzHkZoQBWcSznZAfjptRym
            MotSSwOVCAQYDjHWQlb2fCzLombQaqI1uW3mB18bfN1H2STsuignC50x7ILWyrIZUpGGMl4KGlWL
            Ra2POAqhnLDT93u/0dhOwhlrQRKJlZTbYQCwjrqeytROg7tAXRD4VS+tHfT8wLlQbHEtToVieFAf
            21LsuXLPD7zd/fKRELsDHGrkTDSBTPRd6xwRqSoRRZUYIxGtHFoAZGCCVPFoSo2DwiJCLKt4YmeM
            WgAqgZLBHuB6uUUJoxKMGuqVLUWn13Y281IEb8TUjBXhWFCjSDvqkABNTfJotsVSmnEgg0FDAYMW
            mAM0uJ6xKcEagvpFzo4jlMb0DClK0+t92cRHlKfZpKBUAhTM4ggpcSAXSmoL12BgqKFqDTvcdNeW
            +9eAJbNmvCwDKOF006137vHOE9Prbn/y1js9fNLK8l4Qj9KZFoisiTF674mIjQFARGtf/le7517S
            GldRAxUijgo7u9bD1f9k4aE6rUneQsOXDFApEaDMtrf+bmDFEr/9YZhpVajGaDugGBnQPCkyF0rv
            paMwBguM9WW0IsLlbORbRGIz6JaM8iRyIm2m9UTfQfwNpp2Egt0mU/zOJQNExNJSgUgkUiIicBIi
            U+mliHEEIlBRiiBpRyTcgIZuKLupyZKUxsvi9xuu//iXFniuU2JdGjql8VyvNWZYJc9JFEoJp9ba
            CI0xquhUXhqTRQKqFeUoIALDQhEBM7s6ZIAA4tau+9IdA8l0Ti1QKmC48emxC//Bm+YMFVlHawlI
            YePsCrmzxpscEUlCbSpJ4HOMKxr7Pn+IrKgYceDh1O5DRQ8QKxrsgJg06rZSf0FxQYhdgylLKwI9
            RVoHVDXClkQUVYm8LZQtG4w42hs6DE1AXoHsecvav1vnpjvNoXoQ0V5uCEUaF+aaRJAlYWXmJGFB
            DGXunAkQEWFmr6UPAUCapgPP2wMhqkOMQgxYrlY4GYwISPAMQA2CwpjhVS+rPe9AN7JQwL6HIoDq
            VnxXOlvRm1KDkgCmYKCGiKEkYlk82DunDQSAk+YeL1xx4nshzLAgIBlyzX1jY0kvMYUrS3RZFjJY
            i4Kx1ZlpKhaq9KgcZD9CfoClbjHAcRihrsEUrsxTGxtLbGNfJAMACI7E7f7Bk8IRe2wddd1UWdEw
            SVJLxmO3RaBQQAJHNcRF9N1eeyDLlAHo7P4FhnVmcGhg2fKle//N65CmUUBEgiCIIGMVpFG7jDq6
            VRAT6oNzVnuB2HZ6yEiN7SJpwAXEah0zwKiCgblr5DYmUAHDE5wW0AKFLepNg2lCMDoCAVhAkwg1
            hEwzBSJFB9MGCsgCcBHRIbQIlVfSEzCQRlHmts1bsAVsBzoEYRgItgsMMGhzhTXwZUwMDBlf5s5m
            s5yx+AhriJkgEAXtWEMHAHjvwWSNpSDBWgBWEDkESCoOSqS+7FjXQAe+AQYIPYYBuohD3hRAahAB
            4xFSkML4PCYZCRjQHZtIVMuCrUPBIJIsKtQgQkkpUYB7EbU8gC0IBSOlCGsQSjjnAQPPSiADZaFI
            1YAK7kIzRAsGuBelFhiCnJCpwLFadCENsATRHpuWAhFqQd2Ami1IVCSBZUIklICr5g3VvCoqEdGc
            QGIXAnAC2KDB+gDK1CGCdJ7NCNVz2gF2NSj234YQrLXVTonqs58txmitBVD1837BoijSNAVQlmWS
            JNix0aJfUf9nv6Kqln6GimCfrIgYY0SEaHZ7haqqaj9PpSDOuT7NuUL1KfSlExEAc4lj3oV2oKrV
            ey8iIYR++RBCnufW2hCCMaYsyz46ZVmq6jPQqYCoDGGVbS46Fc2K4760VXEAFTrVeBxCYOaiKOYK
            UD0MIQDo9Xpzm7ASu4+OyKy/MzdPxXn1PMbIzBWsRNQXal6AKqmqCvqsFEVhrc2yrPpSSRt2+OlJ
            klQVVAL0Ua4krwh67/vozG2GuWrbr71PtsK9LMsK5YolEam02DkXQqhWJpi5ErVqy/7n3PYoimJu
            XZXa9hHpM1BlmBegilylIJXfGWOs+PPep2naJzE5OVkV6fV6IYSqxSr4+pJXTTRXWaq31U/nXPW2
            /9w512/zSjVEpFK9Z3T2KlXVVZL3Ra2Aq14558qyrAr2dVlVQwh9ta1+VjxX4ovIbL/901TJr6o3
            3nhj9cV7Xz2/7bbbNm3atGXLlnPOOWfZsmULFy4sy1JE5haf+7MoiurL1NRUn04/Q4WpqlY9NMZY
            deq5pPpPqooqghWdKoP3vmJ4btkqQwihz0Bfrhhjla3PRv/JM0T4XwAaHx9fvnz5PffcM5fcC1/4
            wn333ddaa4x529ve9vDDD/fze+/Xr19/9913b9++/RkSPv744x/96Ef7uPfFjjH2BYgxPvzwwx//
            +Me//vWv96vbtm3bTTfdVAHdLz42Ntan30dKVW+55ZYPfvCDN954Y/Vwenr6Xe96V2VJq5yVIJ1O
            Zy46k5OT99xzz+rVq2+88ca+INWreQGqCl9wwQUAPvWpT/Vb+KKLLqrM0Dve8Y41a9b0262q9bTT
            TqtsweDg4Iknnjg+Pl4x5L1fvXr1rrvuWmERY3zta1/74x//uGrhPM9Xrlz53//938ceeyyA0dHR
            q6++WlXzPFfV008/nYhuvvnmPhxnn3324Ycfnud5VfsDDzxwxBFHfPOb3zzkkEOYeffdd1+zZk1V
            yx133FH12aqWs84664ILLuhryv333/+5z33usMMOGxgYAJBl2b/9279VsvQVbV6AKrEPO+wwZn7V
            q17VJ7pu3brddtvts5/9bJ9E9RljPPPMM51zZ5999iOPPPL5z39+YGBg7733fuqpp6oqzzjjjJUr
            V/YpL1my5LOf/WwF0Le+9a3K0i1cuPCTn/zk9PS0qna73apVXv3qVxPRe97znn4XW7Ro0aJFi/qk
            3v/+91dmaLfddrvooosq41WpwDe+8Q0AfXU78sgj3/a2t6nqxRdfvN9++1VGLUmSU0455Yorrli7
            dm3VAP3O/j8a1Fe2SvOr7+vXrwdwwAEH1Gq1Xq/XtxcHH3zwJz7xib7uVKzceeedzPyxj32sj++D
            Dz7YaDRe8pKXVD/f+c53Llu2rG9lVqxYcd5551VEXvva1wL4zGc+U2m+7jBb1du99trLGDM6OhpC
            KMvyJz/5iXOu2WxWfIYQli1bliTJVVdd1X/S//zCF74AYGJiovp59NFHH3/88Rs2bBgcHDz88MM/
            9alPnXHGGQA2b948n5Zw5XTojjGvP0D0er077rhj4cKFH/3oR3u93n333df3nSrDwczV+FKNtZ/8
            5CdXrFhx1llnVaxUyH7uc5+7++67r7nmmmrgTJKkKkhEw8PDk5OT1fj4yU9+MkmSF73oRfV6vZIt
            SZK+s7dx48bXvOY1W7du/eUvf+mcu/jii733FZGK4c9+9rNlWb7uda+r6q2cEmNMZbn7rgaAVquV
            5/lOO+00NjZ26623nnXWWS94wQsAbNmyZb7RfNaXm+sxV5+1Wq0yCscccwwR3XLLLTy7PQPGmF6v
            V7khfU/y5ptvPvnkk7MsqzpLWZYA3vSmN8UY77nnHgDT09MVHJXeNRqNPkDLli2r+k4lz29+85tK
            YavGL8vyzW9+88jIyOWXX75+/fqf/vSn9Xq92+1Wgzozj4yMANi6dWvF3l133VUx5pybmprq++JF
            UYyMjBRFURRFNQEIIYyMjDBzv+yzAERE/daYi1FRFLfddtsJJ5zgnFu1atW3v/3tfoYQwo033rhw
            4cIsy5YuXfr1r3/9scceK4rimGOOqXyNymcpiqLValVuSDVUVa7mPffcc8UVV4yPj1c9iJmbzaYx
            5q677vrSl7605557rlq1as2aNRVLjz76aIxxwYIFJ5100jXXXPOVr3yFiE4++WQRmZmZqbynwcFB
            APfee+/pp5++ePHi4447buvWrc7NxuG991u3bv39739/1VVXPfroo2NjY2maqqoxxlo7MDAgIlNT
            U/MBNK+RvuWWW5IkqYzCueeeW3FQvTrwwAOZ+bjjjjvvvPNOPfVUABdffDGAO++8U//Ybbn88ssB
            /Nd//dcRRxxRtfPcdPzxx/erGx0drR4efvjhDz30UGUmi6K45pprmPm+++679dZbq4Hm3HPPvfDC
            CwHcc889VX/st79z7uSTT96yZUtla0888cTBwUFjTN/JZuZ99tmncg4rM7dx40YAX/3qV+fDYdYA
            zbXNVTrjjDNOPPHEzZs3n3322dUoeN5551Vi77fffh/+8IerbFXHufLKK4no/PPPDyH0x7uJiYk9
            9thjzz33nJiYOO2000ZHR3fddddLL730oYceevLJJ4877rhVq1b1q161atXuu+9+9913P4OT1atX
            A1i/fn1RFCtXrnTObd68+ec//zmAakCohggAL3/5yx977LG5/tq555677777ArjsssvuvffeJ554
            4swzzxwaGpo7HFX99Pzzz/9zAFVprgtXluX+++9f4dJoNPbbb7+RkZHnPe95Vc5DDz309NNPrwbd
            iy66aPHixTHGV73qVcPDw+12W1U7nU5RFIcddpi19pZbbqlynnLKKbvssku/ig996ENLly7tS/jK
            V77yqKOO6rvLq1evfvDBB1X17LPPrtfr69atK4ri4osvvuSSS6rRbdmyZXvvvXdRFFWRxYsXf+hD
            H6qIj4+Pf/rTn6481Y9//OP9UUhELr/88srizPVRFi1adOaZZ84L0J+O7mVZttttZv785z9fzSpU
            9ZxzzgHwyCOPiMghhxzyvve9L8a4efPmhQsXfuc734kxPvLII6Ojo/vvv/9tt912xRVX7L///gAu
            vPDCvkK9+c1v3n333StEYoxXXnklgMqjU9V3v/vd++23n/f+G9/4xtKlS5cuXbpu3bqyLE8//fTK
            IPZH7orPj3zkIwCuuuqqiuCLXvSit7zlLd1u95xzzqnVaoceemg1ramG+ampqarUmjVrAFxxxRVz
            Idh///1POumkPwfQ3IlJBerVV1+NHTPa6tVjjz2Wpunq1atF5MADDzzllFO++93v7rXXXpW9rGj9
            /Oc/33nnnavevnz58h/+8IfV84rCu971roULF+oOH2dsbIyILrjggirPBz7wgVartXz5cgBveMMb
            Nm3a1O8mCxYs0DmeWuVeb9u2bY899qh0UFUPP/zwJUuWNJvNJEne97739QH92te+BmDDhg26YyK6
            cuXKv/u7v+uzUZblG9/4xkMPPfR/sUE6Z9I4PT39xS9+cenSpZWl7DN34403rlu3ruoOq1atqixi
            laE/VyyK4te//vVvf/vbylfq96CiKD71qU8tWLCg35FV9YILLnj00UdVtdfrnXrqqcaYQw455Lrr
            rqsgqPhZs2bNz372sz5vc6eda9eufeihh1R1ZmbmkEMOAXDCCSfcd999fYZF5JprrkmSZOPGjX0X
            9IorrrjtttvmQnDttdf+ORs0d07cf7phw4bbb79d50xB+0CUZXnppZdeeOGF1VSgPzXvs96fGeoO
            p7n6OT4+XulUlaHv0VdpbGys8vT7ZedOQfup4qcsy6r2fr1PPvlkNer3C/anC5dffnmf7NzmqRqm
            +jKXk2ek2UBP5XQwc57nWTYb8H5GLPVP43VVrMtaW72qgmqVP1mx0o8KVb5sP7LXd1Jml1+87z95
            Rr1zo7pV5v7bvlNewd2P8szlrSJbRYX6rrnOicD2A8TzOorYEdarymRZVrX53Fhq33usInKVe1ZF
            6qqQY/WqH2adPdU3B46KvqoycxUMq+jMjVv2VaaK5M6Nxs7yylxNd/rZ+u3ajzRWOZ/h985Gl5kr
            mv2Buz9P+jMAUT9A2f9SiVERqsT702h2pWhzEanaoR/B63/pu7POOe99JdUz4v+V3vUb9hllqyD/
            3Ih9xWqV/xnh+n51zNyfOVbq0w/792uv1LxCvF/2mQD1O9szclRmslLRZ3SBuRrb6/WesU29v8ww
            t8fN7TjPWHJ4Rv99Rv+aqylza8efrFj8aeq33DPy6B9PPzFPGHdWbat3fSWqIrIVZH3dqYK7fabn
            9udarVa9qnL26+6T7YcK+jHzPo59+n0uKzj60e5qujQXGswJP1fFK8H6RfrZKgb660izQ9IcOPpV
            z+XhWQAiEBQeKiJGDazpVGcHmCMgzApA1BoLIECNMRAwcS4BijZCsBYBTAwvAvXMHaCIAcYUMZAU
            IEIJ5BNMDExy6EKgzAGqzBEipAFRKArFAFXDkRCg1b/qOwRWcmASCuQTKaUKkBTwKIGciZQQAIE1
            Fh5ULfkRBWgJ9EgKog60Yj7GyMQoAkphkFaHvHMfAQiKGDoAIhCilRiJSKsDvAJDZAEEJejM5qcz
            tkpcSsg1pmlaFIVYzmZKDDXEUDnWHshak7acMFqLlJBRQrfbrbkkS+tFxnGoRciD5pYtEjcGdEKD
            o/GKWidUy23Ms6MESGOMPHvA+pkK360bJ3UJsWGxIHMIIWqwNqJAmfeeLmYYFEJoh6LRaCRCCN5U
            RKxpl3ktzeow2s6fbtoyLxpppqq9sqjX6yhkpDWcsE3SWTOSsKnORsMYy1Qd9kWAJgApUgKeGrvk
            zWdOPr2VyxhCSBv1ni8ANNLaGPuRtrRrvPfOuz6yZZMZ72gjHZ6J22uakSm7eWt4kNtF2enFhc13
            f+5jeM0BIIax07G5+rszD/x+U3tScmbKMhHZcYZD2VCl9nNXSv/IWBYxE2kO8UF7rfiHN7YGDEAK
            pevbaz64+jOPd8by6E3ibJYW0zMtV5vhaINQVHKmZCBGjlpX07aRrRWJKH1aqyfGzkxODi9ceM07
            PrFq+V5Ro0F1ql4AKNHs/QgKJRCYiAid4u7rb55+eltBOt0w+WDaLvMhV6sXanu+USqceev3vnDU
            jRe+9fqvNBoNG2R7TRvsXCmDWf1J355umDBcN1G/u/rrkBSwAXhii9y1ZtPamWQmW1gMDHplNYma
            JJL1yqXAK6lxwjaSiWSEbf9fJFMMDM5kC9fOJHet2fTEFgkAYCHpJbdc+7uxTbkJqLtIUnRnqJbM
            5B1IDCQ0kJV5G4gwKnXTrmtCJPAgj8FaWeYz27bZ1HVQXvWLm3tSMnF17pwBMAcVhkQoTHVmvzr1
            kqSb1673Ki9601+f/eNLP/LDi5PdlvR8SdZ0EYeidcOtZfvvnYGXLli04uB9e6wLS9Muc2HKHZ19
            9UUf/MmlR5z1zqmyp1unwCDkgryUyVx7ScOSnQmy1SN4BA+JLGohTAExIEaS6l/1vf8wyFayM0nD
            5torZVKQE3Iwfrd5rRuqwzGKIiGCRnWEmkFiAIk+LGgt2C0dWUINzORgkhgTdgCjDDZxSathrS3H
            J9ds2WDZMkigAIwCBCa2amZP0wugMnt6PsYYJNaXL6JdRhHRRRxiZqbCxHxBK053n/7FA0tefND4
            xqceuv+BXXZaufHxdVmSahGiSu15O/vgyyUD9Xqd2rmUkVMLGFaor/ngbLDNuhGenQYyY0cAVEDR
            VRvbKxug0u9rGQ/6XuItqdZY6wYJI0oZ62pkqpM0a1YMihicE+/BBB9WNkff8+JXn/Hyk2rAH6a2
            XHzXDRfdcu1YomW7jVoGqOcIG6FCjXppkIANEJg5zrLAgBUiE6IQmJmsjQCXhW1k1MyW7rISAAo/
            NDTUXbstD37BPru+55qv/u6Sa647+z9GXWNM8lf8zUkv+PtTv/qu94098PtmYrvtDlSdcfvv/by7
            re0aw9ZAjYkwMOyC4Q5zydotqaGsIsLEAgQNZKm/oj13VK5sNuskJ3VjE5ZgYExkGCaLwKy1rAxl
            6RxCRARgkojX77nqgyf/7UEDy+C1lHIobf7z0X/zgSNfd84Nl15378+35JNIkqwkU5LUXC/0iMiI
            AohMtu8RRrERIBHjXHVxTEBMa+lLDj9sp5Urlx/5gg5prWFf+pYT0ld3ozO7vOAAqqf7n3bSLqe9
            XsEDASCI0WO/9JGx6+5uqmHRKSYXysbuK15zxqmdGoO9khInwccyaLQZoeWLzJAhViIiJQ1K0Tca
            jQULFjy1eVt/8txHB4CXlGwjii9DL/gIdkolMRUWYgT1GmZ65JJUKMvljL951788/69h+PYND//L
            td+6beOakbT1q49/c5ey+Y1Xv/fHhxz2gW99vhybvvh9575k1+df9MuffPiKrzJzZYcDNJFqfxiI
            yCaKkDhWVHfFGDKq+sCPf7b2ezc54t3ecZyAb/raf9V+u/npQf7Iz77rSROllDgBSo6JGI66fGj0
            OxdcvGDcP5n4c99z/Lh14z/6xbWf/nKapge9+VgCPMNIUFt3WkTbNtKINhDECBuvey+tnfDSlUce
            wImd3NxZ8K4vTq/l7sjkRJIumbRJFtd3Y6vOWUaTLQm5HYqUgIXyRLMgIlwoS1Q4YcohqTOLWoNX
            b33ggh9dcdsTD0sIMPbIlXsvaceJlh0u7cuWH/CRV7zliod+cfiuB0Li37/gNR/72ZXwEQbIpZGw
            MgIhVaByep41TWtZTndagdH19Z4Q6ICDD0qHBjvgxMeETR5yyjIwyjJwLXneES9ae/XPFg8MY7Ic
            aSXlWMco5rldAQAK203L1oqh3jtev2DVHk0SLhgd1JfV3Gl/HT7+g21cG56cmkkH8kYxzEmv9LYn
            QpYSa1m8gsmygK2SSZyPgUFalEiTaZ9/6isXjIUeDTVsHg5sLfnCe846YMluCjMMyhN0EfZatPyx
            TevG4YfY/G7rxjA26YeXdxAaiQXDixAZKACdF6AhV/vN92945N77WdB9cmtroD663x4xM43J4sGb
            bz3g+FemWRaBJ66+dZe/OnR6YXLAkS9+9PrbOxs3X/6Bc2Jm8djT5Uw3G57vBhPQTPOI59szX7/P
            yAAeG8NND07eeNujo4uW/+epK44+sHXpT8fWzmTN5ih0HSRCF9t0mrUpWkjIWYXAICg4YRNiBMQl
            tRhCiJGa2cb2DLtEiplX7XzAJe8/b7hEljPYw9lAGFY7SsnaYuKI809btffzb3rkvol6FCaFEdZY
            zS8AqILn16Buu+M2xvZT26KqCTqDXt7pmqneD9770W29mf1OeIVVRNJ13//ZDZdd+c4LPvHL3/zO
            WjswMLTxF/d7xlA0zUbjz5yBGGXz10cv16y47NrtV/2ieKIs6sOLn9og/3HD+D8dO/LWY/Y5/7Ju
            bjvRD9gWTU5warck2QqlRH0vq673YgAIeWmMCZaLbjd1aWAWRAzWtNTdB5Z/+e//eSGMJha57zq6
            fcNDvenuCfu80BHD8sPtrY/eeb2kFhKpUyQgVlTYewmAAc1/qDcfqeWdwgknWcqWqFfcf9k1v/zR
            TbWJ/JDXH22DiQlyaHPvlRNfuf1LR/9tT0M63RWbJEQpcSw9Zan6eQEqIz7xzT/knLcn0kyGl2Qy
            0xv3qb/nD4NsihfvtXg03biRpro+HSxm9hqa8Cg2T3dQyzJrjDEKiVHIsjGGmcE6u3dVBFAw1yi5
            8t0f280MIQBGfsVj//Tlz/9y7cMv3/cvT9j3hW5kYIVrbs+nfCNrCKWDg/uOLHPdAmw4YQCOuLq8
            bf5gmo8ZW0ucx5CX+UiSDSj8ZMm1bPTlfwGNXZgGePlRq+xFV9BEp67aqDemux1KrCX2BJDW5r8T
            o9Mcb8eBXree1HzE09N5UOcI8aDFniJGbHryq4a/eO1Mqxne88q9jz+49tPHwn9+b9NTucCKLyUC
            1gIKTqz3Hj5mtXoMAVHYJtT1p6967UEjK3xZOkpmyL/jwn/9zdNPwOH6J37zZJxZ0hr67bkX+V6n
            WWtlohFxLTOVCQhMiCEYZyGaxzDvFry6x4BaUwSNkg22ioS7iN6gtd+uexz1kpCa1va2jVi2/157
            vPbwroNTyjWWjgSqIbKzUQV+3mCdRz0pQrPXrYcs0GiXRlq2dVBr6Wl/vVBiHcAxh+i+g7Yh8YgX
            1jLjj9zfjtSsZSNKZazu6osQ7fmSACj5svTBw1ru+d1rI+cc87YIdkkCgy70r/Y5sB4JoNDrvu8b
            n/7xo/fe+ej9v29vu/XR++7Z+vivtq2fCtNgqPhIIDN7SYQzbv4uRpKQwhrVyFFCN29mtULjiR89
            Qw1rUX77de/76x9+YXB46Mj3n7L+hrsQyknfS5p155XzQIYFmtg/c7NDDKi5VrNdTDvk+yx0rzyw
            9eajFjViUZjUAoPW/uPf7vWPq3//h3XbhlYOjW3a2h7bYrMlgAdZBStKgoEzJFpFdcWyel8n98F3
            /X2R1eqiUMxQuTgmH3nJ8ZfccDVblpq7euP9N3zlAc1cT7oZZQVr3ePoZQd+9/0fN6krIClzkGhh
            5oxiqv3955VjNiz26abSdL4gaYaer4kd53jAG45u7LcbQD//9Nen1z5553lfPfrzZzV3Xfa8097w
            8Oe+s2SagsMYhya0DvasnVAiAoBjNG1t0KWdXptCXkc6HgeGm7pyQb7vyvpfPn/JHju5Tsff95vf
            /3ps9yaN7TJY7jxid9up9vn37/Xd69f955bxia1ZAfO8xXGv3XfbZwWnKhAF2MToLOcMtGcawwu7
            3Xj+qWe8c8UqjVBDULRKRoK8bmpRu6mgBMDdDEAAJzkEio7F9roYywSxgIlQYwRqMf8oNsVhpGe7
            A82i6znEbs0NLhg++v1/qwa9Rzfc9oMfLbL2V9fdtP/JR++06sBXvv1Nv7/6Flo3VrTbpulsmvTa
            PdPKrLFgaCgjh9YI7XvwQH1g2LmOC+meIwKhPK+Pjfkrb9m6YYtsmXKdcmQo+0NRKjjREBeNtHdZ
            kS1bkJ506DKWpEyWtoty05Ynn24ni5otNmkAEk95jICi1hh2tctO/+fjVxwAMcSAV4gUqV2DsX++
            9AvTibpo/TzXB86X5r+7I7GhXQ66xhb1w4369PT0y97792HpkFVc98VvuvEuTDqU4+YvfevtB35a
            G+4V/3jq9z/wrwuyTFQ8IjmDKKQAeXKksE9vze+7Z1sRo+GQxcHrXR59aRCcYdFYxqiGG61s2tto
            YpJaVbd+ptz0aNey8N1dDR2TmeALJ3Lc8n12G61DcwQuGQ1X62h416pXn/dXr18yOFoaSaJACZam
            tPzvB24/6/IvT7oSVlwZYMxzwWf+fdIAYDh0c3a20Ljbiw/Z/y3H9iCb7vj1wzfeMQSX+xLAU794
            4A8/vl2AvY5+6U4vPaQbvQmal6XJEgnRECkc1BESopoXFzUB1ZSytuUirRduqEeDnhdwMgoeKXIH
            UyYmJMVMvZxpSZEAmjQm2bJZXPohX45AF7q0hSrGl0RJkr0Glvzs3Z/82tHvTEcWfPCHX//Flsej
            4YL1wfaTf/u188+45AtTLsJYmMTzc1OfP6dBsfRDaePJ2GnA5YzDP3gqoJmXb5+/ugVHQSi1HZZm
            cDeu/tZ7DvuLctHgWz9yxn888F6e6rk4J0IuUIVnlNJT0yEXhIsitk3POpcQXIixVCFLaiAcXJG4
            tOYpeoBVbCjqnfFGUXiHLKtRo0aiBTQnYuME9qOveOPL9jp4GPbmjQ+/87L/2DQzvhXdmQNf8siW
            9Z//7iUTLVOkghCpF22WesT/KzD/K0ApmSkpG+yE6JDXvWrpCw4A4Vdfv7LzuyeyLJOE8lBIM42F
            7/5+w51fv+Kv/vEd2HnxX7zx2Lu++l0ugxrL1kRV5gJgB5NaR8jEk7F1SIPSKa+RNRKTqy5/jUKk
            LCnEl9Lx7LNawuKkqLM2i0b0AvJsfCkhprCkQMAbdv3LbXn7vT+57JI7rw8SheKl915/3V03j8ec
            W6lICWJirjWz7tRU2mwWMTwXfObvYgmZ6VgMlhSb6eHvfQsYeHrivq99b8jV8m6vrT6t1UweNDG1
            Wu1X37oaa7fk8Ieddkpr8cK6cZbN7H2TMCqiCKIloSCTg7tKbVciFUoMW0fK5AWlmkj1knKJvklZ
            09eoi27Ze5rHtzTHTZgmP8PoWVu6NJbqQVCLHz5290s/e/rld91gpntUtxhtwdC4tNGywhESU5dq
            8N3OjG01xT83dP4cQDMUFiLthtJkSbrT6AzF6z/7NRpvd31RT7NoWUTqhQaRHkljuvyvT30R1vka
            JwON1LpQlEEkqWWIlpAyEqM1FYtIUGOMi2YgUqMQm3tRVWesIydeQi3tEQKzocTFeksWDPllA8Uy
            40dqNOowiGBArKQRiISP3P39tb2xGSp6i2taFLx9BtGnxqCMiArVwudwDlkWitw/NwMNAFYJBlWo
            CFC1SvBh5LgXmx/c0EkQmOz26ZvecFaSJA/fd59HqDHHGGsRQBTLqQCldhOa/vn9t578YZslnY1b
            YihExNeTXV98SL8dhpcmezSffmJiQHSYjRNqz75iViBAQREWFIUYXkPV+0v4qhEzbsTgbZzYZXh6
            aHSfFKCoUBy724EXP/L4NslRS4VNYpJSQpECQaGAsUA1QQOMgaoBA4ghgMhaG7xHjCZN377yBeNS
            tjizImBQlMrXpahCO1Y2UF2ZTAQftl5zx8MPP9zfVlMdEHPOaXz2dcjA8Aa5RvTKkaxhjBncedlf
            vu5YDGfRz5jEdlG7/wn/u7UzT29rM8RpA88leeoIeMmi5n67tQ7exdXREx/ZNifJf+/unz7w5NpO
            kXMZG2lWSgyWZJ5QVCx9lmXVKbNq28LQ0NDSpUv/dv+XtWzdARQiG6MiZDgCswDFEKq1U5/nziVg
            BsGXpZt781C1hXqea4ipEDieXVCKQIyBQszSBID2QIXARLQKgASO8ee2nDxrAwBBIYQUsGgzIpAq
            MvII6kNiCZQANHuVLua71k53bILobziptmOUgAFIpLqHvVq/CCoUJBraselGdwCh6mV2F0//BOVs
            C8xTsVEoIUAtiERBFAldxKQw1SGzWObG1IAKxBw8z43k8yXpgjOAoFEkZ5cCriiQArDVwdkdl0k7
            KIPmW3CvYmGC/7kyvrr9myAhcgWCRDArkwDUX///n9xz9AUi/1fXUwFClGjYoLoxxdlIMFKd/Y1E
            hLDjMnFGoOfmkljh2UPEonAqIkSWFMoQIERJd0isjAJI56HTx2328nQRZmaC6QseoxJVBkix4yhh
            tWumvy1MRAKzQABUt25HRK6ukJ+vYUJku2N3yw5eRYUpgqQIIbEpabV1CHB4ruNJBLRU56rYRih8
            njkHocBJpRY865QqDAXVdL6L7WSHHj3jvYpUG454thW998ZZqrZ29bcbzXYyKO+oIJalcQ5E1dXc
            8831lFHtKQbAxKKiUayxQAHiCFIQhWiMjZBINnmOTn9JMBoMOMag1hDUVBf/I60MqqoqYccfPMD/
            xeGZqwF2h1rNSh1CdfX97CbDHQWUqi0B1VQnBmts/+es/PP8uQdWlRCNtaAdV/qHWDVtBNTCABQB
            DbBcIiTP0Ux7RAeDqCCjXF1NBVZAPWz1pyUi2BAgf7zd609QqS4p+WP9URVm3SG7qFhiUnQ7HVq/
            /tGdVuxM7Pp/bUBEmPG/zGP/P0zyPyKLgrUsusYSG2OeeOIJABIjqqUO5j+/r/H/47RjZJK819uy
            Zcu6dev+H33iBG7u8K9BAAAAEGVYSWZJSSoACAAAAAAAAAAAAAAAnDy5KAAAACV0RVh0ZGF0ZTpj
            cmVhdGUAMjAyNC0xMC0yOFQwODowNjoyNCswMDowMHMCQXgAAAAldEVYdGRhdGU6bW9kaWZ5ADIw
            MjQtMTAtMjhUMDg6MDY6MjQrMDA6MDACX/nEAAAAAElFTkSuQmCC" />
</svg>
    )
}

export const Premera = () => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px" y="0px"
            width="30px" height="30px"
            viewBox="0 0 25 25">
            <image id="image0" width="25" height="25" x="0" y="0"
            href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHYAAACBCAYAAAAVME6wAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
            AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAA6
            D0lEQVR42u29eZxuWVnf+33WWnt4pxrOOXX69Eh3M4qzcvXeaCIICA0NBPSARCWATIpAgGuIguYY
            DSqQqKjRdGj1E+NNpI0SCdEbjehN8sk1XDVGxYjI1PMZ6lTVO+x3773Weu4fa79VdU5Xdfep6qYH
            +/l89qeGd797Dc9eaz3j7xH+mtPJ7/zp5za9p9xc5U+8Ym7XqLUkKmSZgrZkWYYzQiHglC3xiMKn
            xGAFzmWRWRDOVHnwM/EbjWZRWzYGdTU/4j7787ec+sL1i9v8e+//n99iL/vix0Wow1n+7P1vlN98
            oMclD/XEPhzo5KkP5rfevvp92dL177K9K2mCo+hZJlWFMQ6L4FQwGpEoKIEoLUYNufQJBBq3RU2g
            pcQEyJuWUbz7z65dvuvpN516xtnd7b3+3R/9wU+cG71LZQ3bwBVH5jf/4qknv+aBHNNjjN1FX/ea
            Dz57ee2r/2PlVxiHSLG0QhNAFKyCAQIBlUggAJCRY/CozIiiRHUQHDbklLrJMXPHv/3XP/LF33Rx
            Wy9860ff3uTXvm+uq2rNXPryiV/+8A//rW9+oMZiHurJ/HzQyS+/Ye3k9c9a5j5e5N/7wEt/S2Z/
            +r8PzRmWiwwaIIBGwEMMEMQQjUVNhpoMb6A1IGQ4NeRqcEYQC8H2OVsNv/EV3/M/bry4rV//sWf8
            k7aZ/JOtUMvUlmw0ay/7pu//r+97oMb814Kx7frsB5ansvHmK1/w/775yS99w5uecEOx373/7iee
            //vTs//9xVmYM58ECOwwV0FU0QASBA0QohCiI/oC9TkaI0qNtzPmNuCHl/HZyer7Xnnq0yuoXvBi
            Pf4p9fcMjrrfn4snH13FRnX521/8tv98432N5/7QX4ut+Nuvu/Gm5fPhtYU6zs6mtP3i7k3xL/u1
            jd/6vf2+88K3fvwXm/JJ31oZSxDI0s6LtxEVIBhUIJo0iVkEIaLMUeNpxaA4CAV9KwxMPN3OT9ft
            /LQtS2PEurDRxLq47Nqr7rx7mh/JjzCMAWa3nR5Vn3rSLTc9e/MwY/5rsWJH3hz3mzVZJTyud5xj
            be+y4zL63Zcefe637vedYutzb6G6lRjOEZkTgYiCVqhWSATj00UAH5VWIaohxBwJOYQcRThfR+5q
            9Ph5u3p1u/yEK6rsmhMb89UrC3fN9dO7Nb/26Bp+qmjI8e2R47J8zfcddsx/LRhrxuFFA3KWXR+Z
            RYYhY6lx9CfyiyePfsPX7/WdW25+7npP7ng3cZOoNcEEoguoCUBEBKwFkTSJYgWxBkyOkZKMnEIN
            hQVrDZJbgiuYx5yZzxkOLiO0PXpuwOa5yKjv8BHsYIWpz97+iu/51aOHGvNDPekPNr36+HO+MWug
            Z0pirRQmo0dOPziOyYDVefmfTq49fbjXd1U/8d5hplhraV3LROeYsgRXMAuK7w4yNUpACapENUgA
            68F5MA0MDWhdUVqPaEOeWeoq4sQC6QXxJhDymjkNjcuozPDvHmbcj3rGlo1+T+ENOQ6DQVWIMZJF
            6KtlOeYsxdEb9/ruh378VRthtvlhhxIlkhU59TziW8FlQhTYEYcEo4IoCJqEFwFiJPpIJmBiQGIg
            tp6iNEgGVfBoLsxDjWaWbClnEgybzei7DjPuRzVjX3viua/s1fYrMzXkkiEitKK0oogouQVppwys
            /eH9nlGE9mbmNb5usJLjYkkeMlyn2wKggo2kiwCmJtg5wdaY3BAB1BGjYIwjiKECzteKXXJMYku0
            JZU3nJt48qUrmNZXXfet7/rYkw869kctY9/0hBuKfH3+84PgyMgBIXYSbjARABGlLCzj9XNy8poX
            P3uv53hd/81Sa5Z7S1RbLblA4SA2LVaS8UIUzILJiY1EE/EGqhDx1uB6WRLAosdYCAEGA6GpIoV1
            lM5gEIq+o40gbo3zk9E33fdI96ZHLWP96fpdR+hTkCM4WhE8SjCAEaIJxOgpioKVwYiR2hft9Zzf
            +Mnn1bZe/59+HFgtMrSJhKpmeZChrWJ2M1UUFVAsUQsCBVIYvFFmfs6smRKMJ8sM88mE+WZNUUfi
            uTHh/HnyZoptA23jyYcF2XD52fd/xBfSo5Kxrzj+zC/JK3mXaQUrORFDi9B0nzsSM0SVyfkptjUc
            o3fDfs/LmXxkVBiqKWTWYHPLvPWojQgKAtGkczjiiDhQi1Fo24hqRExOUfQojbuL+foXX57dsdaL
            f3F1Xv3JtUdHZ44v5Z+8fBBvPxmnZ+nnjsq3nB5vfdELXvfh/kHmwD3UTHgwaGTzn8gt9ItltDFE
            ERojqETyCBZNxgSFI/0Vpm3L+VvPX//3rv3bKz/+mQ9tXPy8UG/9/rw6jWTH0czSeEtjasREsmhA
            hWAMikFUMApOFfAYqwRxoIYwz5F2emztqvavbnnbk6s9uv4rL/yeT4f1+cRmA4ulPJq74TXA/7rU
            OXjUrdjXXn3Dq91WfHrfDphXDQEhGFCJCBHRAFFBDUZyghdoYGR6UBeX7/XM3K1/UuMWZSE0HurW
            Y5wFs2O4M2qAzholqT2ISAho49EARWbIbc9x+vxT9+u/xvFdZZmzOR7TAr6xX3SQeXhUMfZ1V7zg
            WHanv3m1HdILBVYygiGpKngyPJaIFUXFEBCiCFJmtLRkub1uz0nS858d9gyqAe9brLU4zVCfETUD
            zXHekntBaFEzp3EebyEvSryP+AjBwCxa1Lgn7jeGcrD8p5ttoBwdxXtD1s+uP8hcPKoYOzT2u46Z
            AUWbYaMDDFHi9mq1MdIpH6CJqYnxSQIK+D0Ze8s/e+OkbeqN0Hp6vYwiM9SzlkxcN4VJ3TERDKk9
            FYgYxpsThr2SLAMfofYtPpiv3m8MG5PxnzXBI1hELFmR7bu6740eNYx93fXP++LJmc1/aIIw7PXx
            bUue50lIQnepI4m1SXo1qKYPBLAi+5rxrDGttZZ5VdO2gX4/w4cA0hmLF6bF6BDNkOgw6lgejWhr
            n2wVAZaW+5icq/cdSAify63BEsG3NPPqCQeZj0cNY+uN2c+t5kMy65jOxogF7xuQuH3PrhORuOfQ
            5fL9nj8Y9O5u25aiKBARZrMW5wwQL2gDtdjosNEiUfC1giqhhf4ANjbWiXBs34FYPh3bOdK2DHol
            EXvlQebjUcHYV1327Nf0p+ZpI9dDPGRZRlmWyRuDppAW3Zl87UyBseO0WRgZ0H3dmCHEc9YIbVuT
            ZQZjDKKAhHQBdDqtxGRatF2TMUZyB9PJnOWVIdO6Xt6vnXa+tW5ijYkR9YqY4rKDzMkjnrGvfvIL
            Rys+e8/x3hGqjRl5nuNjZFxN8OpZWIKgMySo4Z78Wzjl4sp+7YjGqXOOtm5AoVdYvN95ftz1orDL
            aBF94MhyQdSIiOIjbE7bfdvxytjajNzlWHpsje2BVNJHPGP11o13c96vulZZ6o1o2zZZlzJL0SuI
            khzg+y1FkV2fxLhvO/1Bf6uuK1ZWR8QYGY9nWCforjP7ng8HsTCu5jTNPJ35zlEO99+Jl44++W4f
            LFUVUe1RDh6XnTz5QXup8/KINlC8+rrnfOnS3ea71vpLNNOa4CJ5WVA1NRGh8nMM21ITorv/upCS
            EKX7tuXbuVoZUdcNIQijUZ82RhCDiqRvSrd+u59WwTolRqUoCoIKs3lg6JYvf8kPfPLtdn72s6aJ
            VyJI61prR8fvPFPrV9iiQNQQxXJ+U3naF57UW265tLl5RDO2P+WWkZRoE8myAkSofYs6k7ZFERRN
            /NKdzUlYnKmgMWKsQUSwzmzs15YRM4GIqiJiCF04lIoFDLLtm+3aUFAJeA3JNh0jiiNzOT5IsTGt
            3peF4+AtCnhtaTYz6swh1qDSEmLAFAeyKD5yGfvay5/12nCmfqLLht3EplWT9NKF5Set0gXprpMn
            ybPxXtbohRRQE2Wvs8uAdmLXboFMlEjsGJz+JyKogtqMwFGMRKwrO1vznGgCYixRNDEVpfEBKO5n
            L3foEcnYb3nCDUur0+JHe6VgW4NgUHRbgLkHs7qJ3yUXs/uvbXYJ5/ZrUyTe8xRdiNcIF36qRPEY
            Yhd9LIBBFx2LEG1Oq9DG9IjWlEQTUAHBgwkogXKQ82d/dulBh49I4ak/54f8RrvaTGoEi4peEM0g
            i6nUhQ23m27ZxQvueaaqbjuA9iA7RJOhfyEwJYOV7DJ+pOcudosIYAwiNvltoyaVV5K5OgCNQg2E
            CBoNEixGcyRmGHXMJpuc+cLfffQz9tVXPudLzXrzJtcKlx29UMUTIkbT5YLBxoW3JQWrpKtbr2Zh
            WOhWOkIUc37fhgW5p+Qr2B0duNNpk/kyTa0DdWgUjBoc4CS9dGpS6KrJU8yTNWARTATbCKYtwOeM
            +jlPumN0yYx9xG3FR+n/nIaaUW/E2fUNenmRGNQNfRGuIpokXUEuMEakD5PeCmbbQ6OqxEi1X7sK
            K3v9XzS5AYPu2KF39NkUYyWd71eMAgERCDhUwIT0Qhh82l1C3hk+wIrH2Rl/cIB5ekQx9k2X3/Ba
            OTf7ioEviaL0BgOi9xgWBoGIqNne9rq53RZV1VxsXoyARSNEVYj+jntpvg/dNr5r65XuvDb4pM9i
            tl14i4M9/SWgAaUhRk9QIfE04AhY22JNjrCCiAFRrBnj55/yf3DT17aXOlePGMa+6sQNa0uheI9t
            WsqspI5KvMigsGDqYqXu0GJrvJgMoknv8aKoYbxf+4rpp/sTX832ES0gBlGHIXbC26Jfya4Qt18G
            m/J/4qwqwpn3qU4lR3AmukxiYWToGmuf2rD8TEJAY8NwoJfMVHgEMXbN9N+ld81XimyEqmCMgvqd
            1bFrnrlIAlaS2T9ui6Vmm82iETWG1oAP4ex+7dfzsAJgxUCMCII1SX1BBVGbXG0mEEwkiAeJWAxR
            Da0TjAhe+xTt6bP/+Z9+yffv1c43vPOTL5u05TN7rsBqQZg204PM1yNCeHr1dc/50vU7zrzZ4Mhd
            jxAXEuY9tVDd41p8sgg8M7sMFsbAZLJF0e9hTb6+Xx8CkOUF1XRKv3DEmLIBOr7u6oDpjoWFChVB
            0hoO3f0Wv6/6rNQxCARNUrKzdt9d5N7oEbFiVyb254ulYxTao2karBhsZqnbOXLJ8uKFFDSyvLrC
            Z6brtMw39rtvXrej2gSOHV9ic7NFjKOqa1yejAc73RBQt/1GmZgEpyiJx0aVjHrf7dVGd7WNNr0W
            UbAmO3OQcT3sV+zbjn/Dd8iZyZdL42ljSxtbjBPm8zlFVh76+V4j5ycbuF42+YXP/O58r3tOnTpl
            Is455zizXiHWEFH6owI1ATV60aqVTr9NOpKo4GI6ca22WOqt/frjornSdZkEqoqBzx1kXA9rxp48
            ccOa2dB/dixbpW8KJCq9fs5WtUVZlszn80M9P3beF1tm9FdG+07gH9xxednrD1ZaH8l7PVoFmxvG
            04p0mnuiBFQu3GEXweRWwapiQ4uhxpiw71YshGVLRAwgnhD9Zw4ytoc1Y68ge59tDLktiXVLFiPt
            vKJXFMSg9MqDGcgvmABjqNs5rpftu4qiHRab4wlZlqcEKu+ZNz458xfGf4kX6Mqd7SP9jnSuw6Sv
            isi+Qpo1epUYn85v9RD9vmbOex3Xg8GQB4LectULvyQ73byiXy4RXE7pCqQNLI+WCCEwqSsq3xy6
            HRGhblsm7WTfFdsfDY87W+LbyHTS0O8X5LmjCf4ic+LC8cC2dK7sGCwW8VVq3Gy/toK01yANUWoC
            NUjzmYOM62HL2GJLbx5qidiSjckMsRZnLOtn76bMM0ajUYp5OKT01DQNZVmClU/ud49qcbxtPc4Y
            yjKnbRTvI7ldRCnSSb+dkrWrSzv26YX92NFKb19DSMSsQExAJRIRo/4g43pYMvZNl93w6marelqZ
            DYkRsl6fJkSiFXp5SQyBum2SrnJoMoQQiEbr/e4IUdcMdicBi2RJ0hCRaBHdrVwsVqWiRmmCRywY
            Z6maBnE5LYN9dVOV0bKxPYIIQSO+1tMHGdXDTt159ZNfOCpum9/cd6OkSvik6AeTFoTe4128vx7V
            vanIcnwzpvbVvmkUMWYnFrmu0gWxiUKUhc4qBO0iFrf7lM7c4bJja6OmXzrKwZDJtObE0RPFC0/9
            ryss53xoKnF4M8iOZaG3dO3n7jY9Sosah7EZmcZHB2OXztQ/mM0MRa/HxmSTvOwRuwkL23OXTIFG
            LnaZXSKpoa6bZKnK5c79bosi/W2Bd6+wqO4zTUbq7RtVYHPiMTZFdPg2gs2Z1ObN1YZ5c88tITIi
            MxlbrWN2zrB89ComLfigWFXivD2QHvuwYuwrr33u0+X29i0rvWOIsYQ8R7SLAO4CvH2H0mIi7Hiu
            D05lb4ALNfVs/wk01h010STG7WpS4309PYJYitIRm0AIynCYsTWec/mJJ9DMx8yrmmhyStenMEI1
            T1Yumwu52nMu97P7amUvelgxtlj37x3mS2xNp2QmMBgMaJpm224XjCHIIh5hR0Y5DHt9q2xUUzYu
            731635uMuVa6QCntMAjiRUyOnetQO0cB6fYkPTdK2VnLqhmURcnGuCZ4oeytEXxytrsMfAXWgY8t
            3k8mt/zYSysOQA8b4ek7L3vutw+1/zQhY2VljZWlZabjLYwqZntlShcR2OmF971k7oOEqqo5dvQy
            fuOTv7Gv8CTWXm+MgJGdlBCRbWvThVgU6bnJbefwLbTzGlRTHJRvsQaKoqAcjGhCsiHXHmY1lD3I
            LIjUFHncOOjIHhaMPXnib67ZKn5Ap54QYDKtmEzHDMqCTD1WF6qCQdTsYD88ABRQBkujewXLUuEp
            KqAaun6wB1P1wp2ji4XKLJR5CW3AxMDKMCM06Tmbm1Miis3AWBCzOKJnoGOKrL7roON6WGzFa9p7
            l6stq4NlQrQpqmjbr7pDCx/o9hYs6W1ffHbB1iwX+2oXTvJthx2oIS96zKrqXgUU9b2laGw6Chbh
            rLvN/rv+jrKTMmIVoofMgHOOqJ6m9lgDzbxldalPjJHQQmhhqW+ZbU7oDS2ZMSjhtoPO6UO+Yt9w
            7ManL497bx7SR5uIDaGDriNF8YnBGwNdFnqKa1IUQxBDY8CURVpGbURDpN8rmLdzTAYaG4x40BaD
            0vqaPE8B2SEo86bGZvKH+/Xvha/+d6NYryJ2mRaLKQy1BsSm7Tg3oK0nlwJRRxs8ruzgEHykiJAD
            aoSq9YhRNNRkpiVMtyibCjudUBqhbQBbEmLOfBLIGj59/2bxnvSQr9heJT/da/KU5JQMpDsfyoXJ
            GWYPKViC0lZzcjHkeYY4y+mNM5AZ7hyfoz/osTndpMh7OONoCFQ+xSGROZq+0prZz+7XvyNLx4/e
            Xhka25IvlUynAZfZFMQmHm0NZe6o2xQQl5cZk1lDYRyZMwmOz0MgMhyWhHYT9esfn2+dy46t9Iyp
            a1O6QbbRVr5cPnFtHR3GgpE+1vb+6hHJ2Ncff84b7GZ8auKhYIxJEfOqiEgXYL3/gWoVipBeiGBg
            az7jyPEjf3l0tPbys7P2U8Ol1Ss3s8m1TVwdhtI9pVf0CluHJ4VgjhvP48fzyeVnwuTjv/LZ3/no
            fm3cubW5PDxyHYil3mzoAZkVolbE0JJnA+ZNBOdS/wVckTOvGmye4yNkDmww1FvnyOTMx05cef7r
            bnnb37hA2v2W9//l0pmzG7e2vlxSKYlBaLzZ1zHxsGXsm55ww1q4bf4zuZbbiVF6iXqpdO4wVKmJ
            uF7Gradve+LxK078spqtr3/3rf/+T4E/PUw/jTl7Lsz/8qbVwfHj+XDl8nPrm4Pl3pErz0+2JBqG
            bXXWWVtQNTmNOmKVsbI6QtVSh5ailxF96myZOUptzl3MVIBfevMTt77+nXf0smIJFEb9HlmIBzJO
            wEPI2Okdkx89oSNK4xIcu+oFKxXum9EqEK3Q+JpGFWcLVkYr3HXHHY+XXv7Zt1518uk/dtstv3d/
            +rMf/cYHvvU24PUX///kqQ/mA9bMdBCPtlKurRUnhq531dWz2t5wx+mz32bzDMkd46qhzPoJsaud
            s1LayV7tPOsd/9/yuJUMGvp5wXzjDo4dm9160H4/JIx9xYnnPGNlQ1/VlxwjLummezBxwej9SIFK
            G4pRN3HWMJ9NOLZ8jM35Fptnzv/ud173ktf8s0//6s0P9BhuOfXShc/w9u7iBf/ozleeH+vJ0XCZ
            aT3HFBm9UUloAxbFqdC0s8/uORZjrpzPDUfXSmbr5zi2Is1odrBANniIpOKjTfYLS26Er+MFCv/F
            TJT7cMkFA7HMOFdNqJoanTeUkmPawMj1WaYg3LbxgddfceNPPZjjecn3/vFXfOP3n/lYvdn7+Z45
            UUrTx8QhwTsm4xnGCZjIdH4ea8Ptez1D4/AY2YjzmxMGI4P6M2d/7j1fe6BANngIVuzfW3v+q/Pz
            4ZqgLYPeKAWn2RTJAFyS8ARpK7bW0rc51B4rgpWM8XSL0WhIqQWTrfqNb77ixsdt5ZOT+8U1HYRe
            eeqj5fp09Ydm1ZE3TutY9gbLIFDPoeznNKrko5zN82NWBgVLoxG+untPX6yNcpkRodfrMa83sPPz
            Bz5f4fO8Yl93xQuOua1wcy9aiqJHS0RcF1Qd43YA+P09Y0VB2kAPi6sDhQqihsYH8t4QHwxZLDgq
            Q+SO+Y1XhCN//rrrn3XNAzGWl73jj150692Xf3zGNW+f52ulGa1RO2gs2DIlx0sUwgyWeiMycupZ
            IDflnmds3/gvyGMDbaDMS5aPLD1yGFuf3XqP1JGlwSpRhbo9UHDATucVXDApAlDZNr6jgkpyh4dG
            YS5c3juCnGuuZT189pVPee7TD9rmS07926ue/73//VdPV0c+5JaeeF1tVmlsQWsFb7Ur2xIhalLH
            HNDCZDIlRk+5NNwz3kloH5cZgwbYPDemyMsDC07weWTsdxy74euOxPJVa0eOUzUt02pGr7j0hN4L
            JwPyAC52mQC7g8m6oHBb9MnyPqGKlD6nqB1S6Y8cpL0XveP3/s767Av+6Gx7xYvj6DiboSVYJYqi
            tiVKg5oWpd3Omm9mkOWwtJzhXc1mM9vY69malVcZ6yjynDwbsH73xtlL692F9Hlj7LB2Ny+bPuPN
            CdEKo8GoQ105LCUrfJDk0tMu8n5hOw4hcH66Sb83xIrDiqPsD951qa287G0fedKsWf6lzfng2GDl
            SoKUlMOE+WQX2XuiKddS3QIhDCvQtoHN2Ram18McPXb3Xs8ft8XauPI0NVhxjHqDP7zELl5Anxfh
            6Q3Lz3292Wwe38t7SK9g2tQUhYUoe6Zp3F9SOidAdy3ikUQjlmQ2jDFyfOUY1XSTyrQUy73bf/zP
            f/W3L7WtL1j62Cd/f3L55LK148MzGw025LgCsq4nUSGKhZhdkF+S51B56I2OENTxiU/f9n//je/9
            q3zksqypZq0PVTDFqrbuxJePejmxBT+b4117/lL7uJse9BV78sQNaz3czw5sDxsN9WxOz+WMx1tY
            e8koNxdQFMGb5CRY5NDsYCemvPPCGjY2zuCjp6alcfpDB2nr1KlTMc/k49PxFkXmGJQJQs9EOt+d
            S9nuu+ESBOYNZJllMp7SthkrR6//Kpuf+LL18eALKa7/MnpP/sp5PPo0a3Nmk0BmwVph1m4cKNZp
            QQ86Y5ea9idzzfBREesY5CU2KKvLq1T1jCiHe34wXSxUR9HElO1mPNEEUM+w38eWjq224raR+7mD
            tmWD/GcJDU4afGgxXfJUxNIaS7BCcJ7gIsEp0SrGRqyFwhWERiCUxKZk2D8CIcNpj74bEquW1b5l
            cn5KWRpslh0oUHxBDypjX3/Njc8cNNnL/HjOYLRMHT2hbWmbmnZe49zhTgIV7VZq3HZ47/bC2pjw
            iefNlPV2i/zE0k/f8vFbDhxl3rP178a6whgwWcSTcqtUFwjjKXKRbteIEjEW5rOWXHJWyj7MIyYa
            Wt/hwUUIHlRTNMfR4wNaP0H8mQPFOi3oQWPsKU6Z/nn9qeVQsFwMqZsqod11TuckNZpDRRmmUigB
            Oqz/lKboEt5DBBcBbWkzz7qZMV3mZw/eGozmp/+osFCHmHzEmSAmhbyAJ6pivcH4BB2f4OMNuXGE
            qk1qWRvpZyAOWgHJoIlA7vA2Z+Yhc+ovH48fniXQZtd8/B127J9SeIPrMCQXlS0WUQ8PRIiL1YiL
            cVdOqkmrZhEpYaDVwODYkQ/d/Oe/doGn5+VPesGxl3/1y7/k/rb1sz/2gttzJ22R95lVLU1YRP7H
            bVARs1ufFqX1nn5fyIqMrYlnMMipmpbxvIIc6iZQlDBvoTeSFGBuQnvTTa8/UCb7gh4Uxr7xupc8
            bv1zd717eTDctiLZmCbbG2gtXRb4hYACl0pWIQuG9PKk50WS6uONobWGaDO25hXi+cDu777iqme/
            RidbZ0Yu7FsC5Tvf/M+/9h4TlulvaRMZlX1y8q69xWTGDigkIFJjpMZkns2qZW4gFI65BIILZMs9
            WmDQA21n9ArYPB9w1lHX9aFWKzxI6k57butHh7Yk1gErWUJB6xjsE27GA9KwaFcFMmERoLrYjqX7
            aZg3NUuD1XM/fNevfgTg9de9+EtDNf/Xcnb2BUUhmKrZV/q0/av+xTe95bf+6Ei1+ndvuulpLcB8
            3v7XmvnziqyPswmvCbVJOt5VcwcJRCLOOEwmVHVEtEH9Ok4bZmPBYJm1Nf1iibrNiVEJwbAyGB7K
            nPigMPa7HveCr3d3Ny9b6y+RBUNs4w5qS4enZDpBRx6ArXiRbiHbW+DOJhSJhACj3tL3vNG8+GiZ
            m+/bvGv8lqF1LJtlNqpNclkUEL0nbUZrZvbyl5/JqtWXn/rot/3rU884Gyj/H6sRiS3GZF1NWUiY
            4LZLwFqkLKTgt6quKHsjpJn+X0P/qX+a1Wftav8oTRTJ8qHFue/27ehvry6XzOpAlP2zEh4Sxp58
            6slcP3f+JwufQYz4EHEmWxhhDq3a7EW70da2kdkWgNBq6LkcF+WakXdn53XNWrlEqYae9wQtmc+b
            fbe9rRZt8hXy4TXPvfX0x3/rG975Jy/MwtE/2/JQlJb5fI6YkkVmhwj47uVF8wQrr4Esy5hsTVjN
            p//9Iz/yN+8B2/SCd/6PzzVVTu4uZzoec8Vl5aEZ+4CesWvnxm/Lg3tqaVPxIIPdAWTeJTCZLv/m
            3oFk7x9TWxNpbMSbZLM1RKxGshjJY6Qnjun6xrtsHVjNh/S1QFrFakbTenzT7tuFcrR8ZYWlMSOy
            5S/6Mt9e/alQLH9h1dR/NK+nOLeogZbQxKUbUPohoBkac/CWIiux7XzPOOG+a67slxlEKMuS2WTz
            wH7YB5yxr7vixmvMRv3DZXQURY/xZIYt8nus0oUnZsGYwxSdVsBbOoEsdtu7bhcQdBEkRJbLIblY
            tPaIjzi1tFFxWYlzxb6M9XFYmLLHLEJDH1MuuztOn//QsbWj13sEL0o0vsOh6L508a6BwZoMiQaD
            2TN1ITTTgcYG7wOqSq/sH9qI/oBtxUXVvqcX84QN0XhM7mjQ7ZpDpgMcXeDk673wc3fkxL35ZKOJ
            hK4UinaRqy52k7k4c43Q+obMpYKFqoqxlgjUqlT7rNiTJz+Y+3Y1U8kSnI/zzCMsHVk5OqlA3AAv
            kWhaoCX3CbcYcdgOZi8FvackKx8NuGzPUBcRPQbQ71um64H5fHYocyI8QCv2jVf+7WfW56cvWypG
            mJCqV5g8pwotvkOeEzqDwWIwi1WrcTuQ7eBXuMfPiBJRvCgeJe6632sC82o1EtjbXj05MRT1I0y0
            GDxIQCWmhGQhFWPCbGMjB5uqQQt+uxyMAHnR5WeLx1rd05pkbN6LMVJVHmuFEMNhk5IOv2JPPvVk
            Lp/e+qljg2NkWcH4/ISyN0AyR/RtqoocIY8JlHlhsBeNHVbDvW/F9xb3ZDvYDk1VW5M1q3PxxA40
            K5rEWKxNnqSoWIR2ke5h8z2fPayzsnIZJppkQqTL2YGd00PodPGko8cubWTxJiuKbyPBVzgTCPX5
            PXeHNuYrKbUg4jKDNWbPgLdLoUOv2KvP1W/vBfuUwuTUVYPDUhjH2fWz2Mxt4y9crNrslmIPTJqg
            a9OZ2iGtdRB8QRJsbGsg2A7O1giWtGenFWUxptizVMokupHv3IBpW084wou+L9yENjpMzNjJsOtC
            fegERhPolZZ6do6sjHsiv00b28fkZFlGCO2+Z/Gl0KFW7Ldd8axrmtPTdx+1SxCF4CMrgxGNjxxf
            OpK2uo6hQRY1qtgB7N2LVxedqfe2Yhf25vS76Xyxu/XYlE0XSRuDCYp2BRoWqSSFZnvuxa1xRqkB
            gw35ttFjB4y8TYBcIUv1YjsQr4VgGFLZLJrQkhvPcJDjq/GeK9bmx6SaB/I8Yq0hhPbAYacLOtSK
            HQXe0zMFuWTEOlBmBRvTLSREbBuJ8zpBN8hC0Nn5bpQ0+EU04uJa5PDc/8tgcN1Pi8HgVDAJsnLn
            uaTLafq/MQZrDFkmV+01tlCw2nYmLdnGHN55oazSAV13mXwd/PQiJGYxRpfnzJtIVIcdXrWxV1sb
            k9isHT9O27ZUVUWW5Yc23Rx4xX7H457z/Pauycv6+QgJgpNUivrI6AjVdEbeCj0MTUwqSWM7265P
            Aw+2Y67qPTw89zvVo9vPVRJsgEGR2G21pE1BWCxQhaBot4WoTfmOWRb3XLEBa1pjcR0ijIhiJHYQ
            tmCiQaJNequBYDp08eDSNi0JA6qpI0tLq5w/P2NaD775Od9966ctE6pmk3K0IlGOmEntjp4+s4XN
            C5ZXRsRw5qHZik8+9WTubz393hW3BMEkaPQuoWpzvJnAtqxACNsGg9D5SqNZ4AqnEg1taLqoh1Q7
            Pe5C+1XZyY+9mPmLLDyjyZuzOMc7pSO9PCbSmFRywRqHqMdacM7QGsPYzLFZ2BPezdlcWu3KvKAg
            CYREMB1zd5tJ0+6zAMNeIKxGgSIr2NxoEdfDC+/dCpbo++SjKzk9myAxMlwabEMKTSebjHIOvRUf
            iLFXn2++ux5nX9DrDxGxBJP0U1WlyIou9SKgLpVGsIBvPTZzNNZiMYRpKtk50U2ypWIccvlUY6Ay
            EW9U8jKXupl15cREt5dVV1ElYtOKFGXXJi8m8UEkvRhpV7QqBhVjrXHijLFYxJksd6vni70RCqwY
            gw9Q7CobKmy7BxdFlBZHimKJac9IHYkWI+AcZM4SbST6ln6/j48FIQtYo2Qq+BhTxWgFa/rkxn3+
            t+I3X3/jNdNPrf/QWnGCftZP4B+dYTCdL4vVl4SJ4D2ZGHKXMZ7OcIOMNgRG/QHrs3WyKwabk6J5
            8k98+tfvvtS+PJhk2zDMnGUB67OgxUpdVMRKtAsVeXehHaCqWrIyAw1gM9qQzu22jRT9ETqvUnkZ
            VaJKqu4RLzHtcA+6ZMbO7t5677HBKiYIs8mULEui/ralRZIOaTRhRgRjqTWSGSHLSrKY3vRpPabp
            KVt5/fdv+vSHH1ZMBVAjYq1NgGHKPaAPFmQ6uTtZR2NXVMmgko6I3iCjqkGNwVpB61S1IzMZs82G
            XlfhK+m9YddBdDi6JMa+8Zrn3WjXw0tzm5PjyKzdBorskjK2bcPbe6PLOisPFEUOTYOiyMDQZPpH
            N33mwzc9BHy7TxKxEzG6jQQXZa8aWbvgE3agTlETtiMVG29RVbJcaGvoZ+BrKDIwWY6EhbIcEQnp
            aLF2cNj+32915+RTT+bhjsk/z2OGrz3aeKIPGDUXxC6pxORopkvByEoUiw8xifLW0sY5d83Ookft
            qx5i/u1LmYPZbGEBTBYlZaf+wEKn2XERLv6Vxr44f70qeU9ofCo5WhroO0+oKjICJqZ0kGSKjKjR
            BVrxoeh+r9jHr4d3FOXRKxTLoDfAj+dojIjsaAtxOzpvoahbZpMKBZZGy4R6RhsbpO8whfuXP/mJ
            X/vjh5qB+5FEVWdTJax0nu7GStweMYvKHttMVbvNVAWMEeo60sbAsDRMN25nmAk5gWYGeX6EIDmB
            BThYRIyODtv/+8XY111/4zXxzuYfURtMlrE52eDocDk5miXFwieE7R3co0V9miP9EZPJjHpzhskj
            43bKuh/DseLNHDoA5MEj347r/jBn23+mqQxLV6IJ6AQpFSCFxqQyLLpTjkW649NESqeoP/fvji/P
            /9K1m1YypA6Z9WplbgbfHsh7QZPbEY2Hi6TnfjK2ty7/NFaeXn8ZgyHPLU3TYMxOPdaFTXg7trdb
            tZOtCaPegLZtqbVmQkt+5dJrfupTHzl0wNaDSbYsJxvjDcrRiIt9xopgNbC9Le+Cu40L9OnOlhy8
            p1caxrNNVvPmx37lB59wD+iEF3z/nV/VRvdVJjpUIzHG6w/b//vcy9947Hk3DmvzjXkxYBYi0StZ
            dwQEjZ2LLCRXltlBLYud77NvLHkUXPRM6zHm+OBjP/XZjzzg0AEPNPn63GS3ZS+ihIu0kMWLzGKF
            6oW7lVHIrEuQe82cQud7phca3Vx3KHjIbMagOLyb/D4ZW9T661kwKdrOdN6aXfACmIRvmAK2d8rX
            byv0KFW9ic2F1gbOxcm3PzSsumSq82xhAb6QduKGI7oo8rBgMLtOYgVft2TWcWRllXl1frgnE3Q8
            3jx3ln5eUM8qQvRXHLbz98rY1x97zve7xohRQyYG172NbWeE2G2w32vgIpCVjkhkfb5Bcdnw/b90
            92//yeePNwen4V2TqVBvqLYpQ3KPcabCdS3R1CmWmBR3tTs8ZtDLmM9qtrYmWJE9GSZx8rnV5WWq
            KjAcLhEw1x62//sy9g2Pf/EXlePwA5l2Za5jiroPhM6E1qGQakS78rfowoaafKRilM35mJmdUw2U
            u5s7Lzkv9aGiW255aSgLCTv66d7GoLioBbCdI+tR0W2M5baFvOwhJsd79rRLz+vx7bPJhMHAkrkc
            m+XHD9v/fRnbO9f8wKDNKFwB1mBDxPi07UQj22eoXgA7m4zyViFl50TMkuMu3WJ8hG//ubP/9dDR
            d59PqmYbdyvt9gpcqHJxj6lLwtMOcxcUNGKdBVcyGK0e3audMis/k2WWra2GST0navb4w/Z9T8a+
            9poX3xg26pcsFyMyHE6lK5GZIn2kk/JDKqC9KyKhe2g0SFBEA3dPztK/evXPf/bWjxw4ffGhoszp
            XWjLAuP/nnBFdjv5ig5yd/eEqoDNDPM24IPg1V69Vzu+8Z8xmWO4lJP3Sqq5H3JI2ouxYu+cfviq
            41czruY4r9iQRLxoEgiVjQtofukGrJ3LLLnjiGB9JARP7Flum59+2UPNpANNTsbpFGy3KCR8EakB
            zTGx6HyzdicypNu6q7olKyzFoODs+Y091ZhazWetlbg5nhEieG/yG77lXy0dqu8X/+OtJ150akkd
            W+c2GfVHLEq0qumiEegqG4sSRLvzNsUu2EgX1KW0ROYSicPs/bfc/d8eEQLTxSRV/ZmFd0fNxYLT
            IsZJdgWJpyldWKEExdn092xS0+sfecKpU6fuMef18tWNuMIvL/ehjVTTliMnTlzFIegChenlV/3N
            L8nH7fe7qOQdMFZjU/6pdOUUU2RhwFjwYmgzR9tGliSjqqaYQUHdrfBZFmF18E4OnbDw0JCN9vao
            gWCTqy2SgtEXnFHVzpvDrmSk9OJL9w1nLDSBJecQXTr6+9Nvue5Z77hxGxFmLmsGesXYm9uc1+vz
            YCjMErHlGuDjB+37BYw9EZdPha0Zy9ky/d6QM5vrlOUQMLhtY3eqmyqa1Jm2DZgoWAO9LKeOEXWR
            uXomuX/NBz5+y+SAfXvIKaP9ZNLVdVcJb01luS+m7UIQ5sJKHyHZk41GlB4Tc/kfxrhWqETnRbwa
            5yazUA3LfChRwIMxjqj5U4DfPGjft7eFN1zzvJeG8/WLj4/WKG2Pjc0xK6NjnT8ynR8SLUYdNqbL
            BUNPLEf7A5r5DN9WOAJ1M2GaVR/7wPp/fNhbmO6NMsInjInpGNJUHct0oTKpRNkCzj4p+CneygKu
            O38dahyIIRpHlBxstmTyvJDcWJdLkTtsJjo0Emn9PKWN9Cx1nv0fh+m7A3g6T3fDOv9p6kDrW0KI
            FEWfWdMSjUEk1b4xEaLKBclU2nhmzYRhWdC0AVdY/LylHuo/ZOOhZs3hqMymZ0TieYiryfa7l4en
            K+LbxTgtIhZRux32ukg/SVtdBsZgTDraVJXBsEdTBVQMvaGwsTGnNeWhVB4D8OVrK/8gnKuOrZRL
            FPkAV5bM1OMzaGyktbHLakvZ6I2FxiZM/l6vByjzUCOl4/TGndTa4OLgUDjBDwf6ufe8aGzE32Y6
            CD1IFqgooMZvFyJMH+z6dZfNeCFPb18q+GjxocCHgqCW2bTB2R5lWbK+WdNfXsO7q7/wG/7PPz6w
            w918V/4NT3Gz8INFzMhMxng6po2hq1agIEl42k7BX+SldCpA0zS4PMMbmLY1WV6SDcrfuemODx8K
            9eThQsbMP6kaL6wJv111MiV9JCk4zc/FFqrYhQqlIoa7BK2Eok0MlrwcUc8jGqDfL5jOaxotyny4
            /OQD9/uK1ePvaqZzjqysUFUV1mWoE0SgqWdk6smipwiRIkRKHylDoOeVPCTJsAottl9iypKtpmLQ
            X35Yu+QuhVxsP2Zii4TYRYos8nRAF6tXNFmbTNslcO0wV0xETIs1DVY8ThKamw3dJVlnA7AJYl4r
            XNaCiTTzja87aL/N+pn1b+mZgvmsJnMFeZ7Tti2x9Sz1B7gImabY4N04C6nXiityVJXpbE7deFaH
            x6inzRdzmMTXhxFZmf2OjfMUvrI9dLN9LdDgFqoOgNlOOFOIDaI+FWPSgIlJVrFdXR4XQGooDWQK
            plWMOgICxn3VQfttcnXkUjAsBkQfqauawmQUxqF1m/S2DvElmpQt1xjD3EK9CKaOSi/LU/C2t5iZ
            ecJ3LD//7z/UTHkgSKr5Xxg/IxPQKF3G3UJL1R3/c2e6oXOCICmOCfUYETIynFqkbbA692F++4a0
            d9Sm3qhMtVVLtdnaek5JH+qSMh8xqfQrD8zYqFD0Ss5vblKWJb3eACvJOZxLl0aoBhFLNJZgLd4Z
            onXgDG1oKLJUNNeJIbcZpoXltvyRt6+8+N9/x9rzXvXita/52oN28KGmD/34MzbC7OzHitziXAod
            rX0gzyyh3SnNltTCjJSLIJhOBun1etTzlnqWKkRbjZSuuum//MRVq732j5fHk79aHld/OTpy5PZh
            Ge48MdvcuLWwMK1qxJZP/Dtv/7UDMddVtLi2YbA0YjKtKVxBDIHlQXfmSqpbFTq920skqOAFJEac
            UULbkBmSI09gWPapqwqrPH82rZ+/ctkIHsFb82qv/ei42vzfTG+NaZ380MYkZNWUN53wJi7QAzsd
            dzabMRgsoXOYbMDyMGdr45NfDfAbP/m87TSEDnHk7ue89ZMfa7S8uuhFctOnmhz9Wzsf338y+fHR
            v9rMGj47Pk3TF8Y6Y8NvsTkf02pLQ6CRhBxaU9PGFh8bQmjw2jDRGVvM2IgT6jywEadshQlSCmoi
            tnRMZpOfeKiZcxgqdPzRtpmyuVkxHELwSjNXBmWPxfu6HYa6OG67bHfB4ttI3cBoOX13MCy/+Fnv
            +OCeebk6v+MXQ30OfEXVKOvz/JsP0mf33tMf/LaXrz3zl3qj3t8556dfNBr0RoUOaOsWo2A7sNYU
            +bHYYiBGtLWBdmR1s/JaFBmz6SblytCIV6sWAgFfuiOI+xXWD9K9hwnVW/9lZTUfayxHW9PIsTXD
            2btm9Ho9DLIPzFF3DttIlicHybgCaVus0dwY8wzgQxd/64rLwm+ersN8IlJat0TI177qhd/725f9
            +rufdUnZEo/Y7fHzTTd891/+p7Ny5de7okfTBPpGKApD1SEeLqIWpeNyMCkjoApjBv0RzcySC5QW
            Yn2OGP/i/R9939e8Za+2nv8P/uDf13Lt81sZ0M6nFP5/veOj7/+a91xKfx/yapSPFMozvVk1YB24
            zJBlhtl0vu3twtSIVAkDo6OIpTco2ZqfR7M53kDVQJYfZfPcyvNPnvzgnvHDo97mzzRbY5wv6PeO
            YLITL73U/j7G2PtJbXv77+SyGWbTLZwT5nVFnrttY0TchiW6MDvP+4QE45wQST6/6RwGS9c9vr7q
            C758r7b+zT/8+o8MM71bPNQ1NKH8ypOXKB0/xtj7Sf/hvc+464i96/dGRYtvawIeU3QgJhhUCwIZ
            AbtTBVoC+EhhcrRDZ1VNWMXROcZtfMl+7dm48ZPILKnE+ZCN5sgrLqW/jzH2EqhvTv94vXWa3AhF
            r0dV17vSK3clbLEjJVtsl8FvMLqTLK1W8Fn5za889fPlXm25fPYvjKubXh+aAPSvfOUNb/oP9ztc
            5jHGXgJ98B8/78PLZVbn6oitRSgwarqICY9Vv12GPKk+toMRyjqYInYCy62itn/duP6y5+7V1q+9
            72tOG9f8wtZ4Rq83oprnS40Zfev97etjUvEl0vPf9rF3rldX/FDv6BVUHqyhM/6n2GqJ2XbGXfpH
            8uyghtBFnYhA1Abjt1h1/q/ifONjRI8zrZQD42rfSrQrcXNeXK/u8q+wxiFxjgu3j/vNn1zzoR9/
            8cZ99fMhL+X9SKM1O7nZjvhHVUxFJpPt3yCygD1epFZKF07TIdssUi0X4F+iqBswxz6+0fLxkivD
            pZLP3HkH/UGJU4t1PTAOH0G1pI2jka+PvR34vvvq52Nb8SXSL7z3GXdZf9fNk42zuxxdFu1AqOGe
            iHMSu615EWGhoctIdNSSU7slQn+V2zYb7PLVxOwyolkmBEc1qylLqAMEGTI89qTveOFbfvuy++rn
            Y4w9AI16Gz8wKuqQd1poMve7bYTxeJHKcwHt1nPFUAeIuaG1gWypT8hygnU0wYEp6A8LtqaQDyCY
            nNPnw9Hg1n70vvr4GGMPQP/yHz/z9kG++WPV+BwLzyUqKG474zAsoiUWJKnOndEOmiDF1yA2eYzm
            dYWGQIwwqzx5maESaYOSFVBVgXLgKEfHmfnRt73wrf/xaffWx8cYe0Bq5NPvLrPTcycN3pP0TSu0
            KrQKJoNWWuJ2vRYFDUkgRjCkIhgCaFRKyci8kEelnwm+nSB2TjQNSiQvhNZHogpkR02lj/vAvfXv
            McYekD7yIzeev/LI9HXt/G6GPQitUs0ivdIg1lAHj1hQ8UTj2R15stBpJQqGkGoHBUcWclyQbSTX
            ZMVSAu12TR/EEMyQ1l72pc9+6x/uWy71MXXnkPTst//hR6W4/ulky4znSkOkN7DMqhlFmYGPqQ7C
            IsV0G6ovRaBHk+CUTHSYKEjsEt5cIJh2p/KJGkQTerkqRA0UeheDrf9x46//zI0fubhfj63YQ9LA
            3PWa+danYqjOUVplWFraGvI8x/t2J0NgF1N3rydDQDSmFbwdrA2qFqUgLuKWoyTLlXbYjc7i7ZCY
            X/fzN77hw1de3K/HGHtI+tB7n/dXV6xULzPzM7h2RjttKR04axNqncp2HNTCq72gKDHFTHUVSFQS
            rnOQFMYct4sMO4xadLGbC6gEouTE8nFrW/NrfvmGN/2HC/AtHmPsA0D/5oe+5lcyPf+LppkyyjJ8
            lXJ2mvmi8OUCVn5XSHFXrGc7EE5SNZJoE+TvonRqysldgG0rSgpzRZVAQe0G2CPXfU2w1/7y7j49
            xtgHiI4frV/XTs7+N+MDmQFfe4b9AbudA4ts+NiFqqrE7Vp8rQithdbV+GyO2guxLNIvLeJmIDVW
            HVEN6z7SDgva3pEXPee7//hnFv15jLEPEP3CqWfMV/qTl0637vxEaFv6ZUE1qdhvilXiTqhqF5+s
            u4LPhabLviAlfAksat2IyHa2Y94z1FHwps80DN7wonf+6U3wmFT8gNNz3vYXXzovrvmtiFtTY7v8
            np2QGUhnqnQH5k7q5U5+7cLevJNEvaC44yaM2QWQS44WDRNox6yMej/9/wPj9STYI3aKYAAAACV0
            RVh0ZGF0ZTpjcmVhdGUAMjAyNC0xMC0yOFQwODoyODoxOCswMDowMHyIpZ0AAAAldEVYdGRhdGU6
            bW9kaWZ5ADIwMjQtMTAtMjhUMDg6Mjg6MTgrMDA6MDAN1R0hAAAAAElFTkSuQmCC" />
    </svg>
)
}

