import "./Blog.css"

function Blog() {

    return (
        <>
            <div>BLOG</div>
        </>
    )
}

export default Blog