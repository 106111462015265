import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import Backend from 'i18next-http-backend';
import {initReactI18next} from "react-i18next";
import translationEN from './locales/en/translation.json';
import translationRU from './locales/ru/translation.json';
import translationHY from './locales/hy/translation.json';

const resources = {
    hy: {
        translation: translationHY
    },
    en: {
        translation: translationEN
    },
    ru: {
        translation: translationRU
    },

};

const Languages = ['hy','en','ru'];

i18n
    .use(Backend)
    .use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: "hy", // use en if detected lng is not available
        lng: localStorage.getItem('i18nextLng') ?  localStorage.getItem('i18nextLng') : 'hy',
        keySeparator: false, // we do not use keys in form messages.welcome
        whitelist: Languages,
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;