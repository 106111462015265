import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Layout from './Layout';
import React, { useEffect } from 'react';
import Admin_Ekey from "./Components/Admin_Ekey/Admin_Ekey";


function App() {

  let ScrollToTop =() => {
    const { pathname } = useLocation()
    useEffect(() => {
      window.scrollTo(0,0)
    },[pathname])

    return null
  }

ScrollToTop()
  return (
    <div className="App">
      <Routes>
        <Route path="/*" element={<Layout />}/>
        {/*<Route path='/Admin_Ekey' element={<Admin_Ekey />} />*/}
      </Routes>
    </div>
  );
}

export default App;
