import  "./Button.css"
import icon1 from "../../img/Iconarrov.svg"
import icon2 from "../../img/arrow-rightarrowWhite.svg"
import {useTranslation} from "react-i18next";


function Button({setIsTrue}) {
  const { t } = useTranslation();

  return (
    <button className="button" onClick={()=>{
      setIsTrue(true)
    }} >
     {t("button_text")}
      <div className="arrow_bcg">
        <img alt="" id="img1" src={icon1}/> 
        <img alt="" id="img2" src={icon2}/>
        </div>
    </button>
  )
}



export default Button
