import "./FastFood.css";
import headerPic from "../../img/iconsFastFood/FastFood_mainpic 1.png";
import headerPic2 from "../../img/iconsFastFood/Rectangle 45burger2.jpg";
import Button from "../Button/Button";
import Car from "../../img/iconsFastFood/car.png";
import Tablet from "../../img/iconsFastFood/end-of-line.png";
import sefService from "../../img/iconsFastFood/self.png";
import bacground from "../../img/Groupbcg.svg";
import comp from "../../img/iconsFastFood/Tablet 2 2hamak.png";
import icon from "../../img/iconsFastFood/checkarrow.svg";
import comp2 from "../../img/iconsFastFood/Tablet 2 2.png";
import Img1 from "../../img/iconsFastFood/tv 2garnirs.png";
import Img2 from "../../img/iconsFastFood/block.png";
import Img3 from "../../img/iconsFastFood/tv 3.png";

import icon1 from "../../img/iconsFastFood/Icon.svg";
import icon2 from "../../img/iconsFastFood/Icon (1).svg";
import icon3 from "../../img/iconsFastFood/Icon (2).svg";
import icon4 from "../../img/iconsFastFood/cloud.svg";
import icon5 from "../../img/iconsFastFood/globe.svg";
import icon6 from "../../img/iconsFastFood/wifi-slash.svg";


import {useTranslation} from "react-i18next";
import {Swiper_carousel} from "../Swiper_carousel/Swiper_carousel";

function FastFood({setIsTrue}) {
  const { t } = useTranslation();

  return (
    <div className="fastfood_wrapper">
      {/* header */}
      <div className="fastfood_header">
        <div className="fastfood_header_text">
          <h1>
            {t("FastFood_title")} <span>R_Keeper</span>{t("ov")}
          </h1>
          <p>{t("FastFood_sutTitle")}</p>
        </div>
        <div className="fastfood_header_picture">
          <img id="pic_for_laptop" alt="pic" src={headerPic} />
          <img id="pic_for_mobile" alt="pic" src={headerPic2} />
          <div id="button_component">
            <Button setIsTrue={setIsTrue}/>
          </div>
        </div>
      </div>
      {/* Popular_equipment_categories   */}
      <div>
        <div className="second_title">
          <h2 className="title_h2">{t("FastFood_title2")}</h2>
          <p className="subtitle_h2"> {t("FastFood_sutTitle2")}</p>
        </div>
        <div className="categories_slide_wrapper">
          <div className="categories_flex">
            <div className="equipment_element">
              <div className="text_wrapper">
                <p className="equipment_text">
                  {t("Hardware_text1")}
                </p>

                <p className="equipment_subtext">{t("Fast_service")}</p>
              </div>
              <div className="element_img">
                <img className="equipment_img" alt="img" src={Car} />
              </div>
            </div>
            <div className="equipment_element">
              <div className="text_wrapper">
                <p className="equipment_text">
                  {t("Hardware_text2")}
                </p>

                <p className="equipment_subtext">{t("taking_orders")}</p>
              </div>
              <div className="element_img">
                <img className="equipment_img" alt="img" src={Tablet} />
              </div>
            </div>
            <div className="equipment_element" id="column">
              <div className="text_wrapper">
                <p className="equipment_text">
                  {t("Hardware_text3")}
                </p>

                <p className="equipment_subtext">{t("self_registration")}</p>
              </div>
              <div className="element_img">
                <img className="equipment_img" alt="img" src={sefService} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* block3*/}
      <div className="block_list">
        <div className="support_text">
          <h2>{t("subtitle_3")}</h2>

          <p>
            <img alt="icon" src={icon} />
            {t("text1")}
          </p>
          <p>
            <img alt="icon" src={icon} />
            {t("text2")}
          </p>
          <p>
            <img alt="icon" src={icon} />
            {t("text3")}
          </p>
          <p>
            <img alt="icon" src={icon} />
            {t("text4")}
          </p>
          <p>
            <img alt="icon" src={icon} />
            {t("text5")}
          </p>
        </div>
        <div className="comp">
          <img alt="phone" src={comp} />
        </div>
        <div className="hidden_comp">
          <img alt="phone" src={comp2} />
        </div>
        <img id="BCGimg" alt="bcg" src={bacground} />
      </div>
     {/* block4*/}
      <div>
        <div className="title_5">
          <h2 className="subtitle_5">{t("subtitle_4")}</h2>
        </div>
        <div className="categories_slide_wrapper">
          <div className="categories_flex">
            <div className="equipment_element">
              <div className="title_5_text_wrapper">
                <p className="title_5_text">{t("Electronic_menu_boards")}</p>

                <p className="equipment_subtext">
                  {t("Electronic_menu_boards_text")}
                </p>
              </div>
              <div className="title_5_element_img">
                <img className="title_5_img" alt="img" src={Img1} />
              </div>
            </div>
            <div className="equipment_element">
              <div className="title_5_text_wrapper">
                <p className="title_5_text">{t("Cash_stations")} </p>

                <p className="equipment_subtext">{t("Cash_stations_text")}</p>
              </div>
              <div className="title_5_element_img">
                <img className="title_5_img" alt="img" src={Img2} />
              </div>
            </div>
            <div className="equipment_element">
              <div className="title_5_text_wrapper">
                <p className="title_5_text">
                  {t("Technology_electronic_queue")}{" "}
                </p>

                <p className="equipment_subtext">
                  {t("Technology_electronic_queue_text")}
                </p>
              </div>
              <div className="title_5_element_img">
                <img className="title_5_img" alt="img" src={Img3} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Advantages */}
      <div className="flex_wrapp">
        <div className="element">
          <div className="element_icon">
            <img alt="icon" src={icon1} />
          </div>
          <h4>{t("Full_compliance")}</h4>
          <p> {t("Full_compliance__text")}</p>
        </div>
        <div className="element">
          <div className="element_icon">
            <img alt="icon" src={icon2} />
          </div>
          <h4>{t("System_stability")}</h4>
          <p> {t("System_stability__text")}</p>
        </div>
        <div className="element">
          <div className="element_icon">
            <img alt="icon" src={icon3} />
          </div>
          <h4>{t("Reliability_and_speed")}</h4>
          <p> {t("Reliability_and_speed_text")}</p>
        </div>
        <div className="element">
          <div className="element_icon">
            <img alt="icon" src={icon4} />
          </div>
          <h4>{t("Remote_control")}</h4>
          <p> {t("Remote_control__text")}</p>
        </div>
        <div className="element">
          <div className="element_icon">
            <img alt="icon" src={icon5} />
          </div>
          <h4>{t("Network_management")}</h4>
          <p> {t("Network_management__text")}</p>
        </div>
        <div className="element">
          <div className="element_icon">
            <img alt="icon" src={icon6} />
          </div>
          <h4>{t("Offline_work")}</h4>
          <p> {t("Offline_work__text")}</p>
        </div>
      </div>

      {/* sliding-line  */}
      
      {/*<SlideLine lang={lang}/>*/}
      <Swiper_carousel/>

      {/* Blue_block */}
      <div className="Blue_block">
     
        <p>{t("Lets_implement_automation")}</p>
        <div className="button_wrap">
          <button className="Blue_block_button" onClick={()=>{
            setIsTrue(true)
          }}>
            {t("Increase_profit")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default FastFood;
