import "./AboutUs.css"
import {useTranslation} from "react-i18next";

function AboutUs() {
    const { t } = useTranslation();

    return <div className={"AboutUs"}>
             <div className={"AboutUs_header"}>
                 <div className={'AboutUs_header_text'}>
                     <h1 style={{color:'#ffffff',fontSize:'50px'}}>Ekey.am</h1>
                     <h1>{t("AboutUs")}</h1>
                     <p>{t("AboutUs_h")}</p>
                 </div>
               </div>
             <div className={'AboutUs_content'}>
                 <p>{t("AboutUs_text_1")}</p>
                 <p>{t("AboutUs_text_2")}</p>
                 <p>{t("AboutUs_text_3")}</p>
                 <p>{t("AboutUs_text_4")}</p>
                 <p>{t("AboutUs_text_5")}</p>
             </div>
    </div>
}

export default AboutUs