import React, { Fragment, useState } from 'react'
import "./App.css"
import { Route, Routes } from 'react-router-dom'
import {Header} from './Components/Navbar_/Navbar_'
import MainPage from './Components/MainPage/MainPage'
import backgroundImg from "./img/Vectorbacground.svg"
import backgroundImg2 from "./img/Vector 1.png"
import FastFood from './Components/FastFood/FastFood'
import PublicFood from './Components/PublicFood/PublicFood'
import MobileApp from './Components/MobileApp/MobileApp'
import Retail from './Components/Retail/Retail'
import EntertainmentCenters from './Components/EntertainmentCenters/EntertainmentCenters'
import Fitness from './Components/Fitness/Fitness'
import Blog from './Components/Blog/Blog'
import Hotels from './Components/Hotels/Hotels'
import Cinema from './Components/Cinema/Cinema'
import Hardware from './Components/Hardware/Hardware'
import Popup from './Components/Popup/Popup'
import Printers from './Components/products/Printers'
import CashStations from './Components/products/CashStations'
import RetailStoreEquipment from './Components/products/RetailStoreEquipment'
import {FooterWithSocialLinks} from "./Components/FooterWithSocialLinks/FooterWithSocialLinks";
import AboutUs from "./Components/AboutUs/AboutUs";
import {LoginPage} from "./Components/pages/LoginPage";
import {RegisterPage} from "./Components/pages/RegisterPage";


function Layout() {
  const [isTrue,setIsTrue] = useState(false)

  return (
    <Fragment >
        <Header/>
        <img alt="bcg" className="BackImg" src={backgroundImg}/>
        <img alt="bcg" className="BackImgmobile" src={backgroundImg2}/>
       <main>
        <Routes>
              <Route path='/' element={<MainPage  setIsTrue={setIsTrue}/>}/>
              <Route path='FastFood' element={<FastFood  setIsTrue={setIsTrue}/>}/>
              <Route path='PublicFood' element={<PublicFood  setIsTrue={setIsTrue}/>}/>
              <Route path='MobileApp' element={<MobileApp  setIsTrue={setIsTrue}/>}/>
              <Route path='Retail' element={<Retail  setIsTrue={setIsTrue}/>}/>
              <Route path='EntertainmentCenters' element={<EntertainmentCenters  setIsTrue={setIsTrue}/>}/>
              <Route path='Fitness' element={<Fitness  setIsTrue={setIsTrue}/>}/>
              <Route path='Hotels' element={<Hotels  setIsTrue={setIsTrue}/>}/>
              <Route path='Cinema' element={<Cinema  setIsTrue={setIsTrue}/>}/>
              <Route path='Hardware' element={<Hardware  setIsTrue={setIsTrue}/>}/>
              <Route path='Hardware/Printers' element={<Printers  setIsTrue={setIsTrue}/>}/>
              <Route path='Hardware/CashStations' element={<CashStations />}/>
              <Route path='Hardware/Retail_store_equipment' element={<RetailStoreEquipment />}/>
              <Route path='AboutUs' element={<AboutUs />}/>
              {/*<Route path='Blog' element={<Blog setIsTrue={setIsTrue}/>} />*/}
        </Routes>
       </main>
       <Popup isTrue={isTrue} setIsTrue={setIsTrue}  />
       <FooterWithSocialLinks  />
    </Fragment>
  )
}

export default Layout
